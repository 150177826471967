const COMPILING_CSS_CLASS = 'compiling-css'
import Rails from '@rails/ujs'

class StyleManager {
  constructor (inspector) {
    this.customCssTextarea = inspector.querySelector('#custom-css')
    this.siteStyleSheet = document.querySelector('#site-style').sheet
    this.customCssStyle = document.querySelector('#site-custom-css')
    document.documentElement.removeAttribute('style')
    this.rootVariables = {}

    this.advancedStyleHeader = inspector.querySelector('#advanced-style-header')

    let codeRefreshed = false

    this.refreshCodeCb = () => {
      if (codeRefreshed) return
      setTimeout(() => {
        this.customCssTextarea.dispatchEvent(new Event('code:refresh'))
        codeRefreshed = true
      }, 1)
    }

    this.advancedStyleHeader.addEventListener('click', this.refreshCodeCb)
  }

  dispose () {
    document.documentElement.removeAttribute('style')
    this.advancedStyleHeader.removeEventListener('click', this.refreshCodeCb)
    this.rootVariables = {}
  }

  compileCustomCss (e) {
    if (!this.customCssStyle) return

    const compilationButton = e.target
    compilationButton.disabled = true
    compilationButton.classList.add(COMPILING_CSS_CLASS)

    const formData = new FormData()
    formData.set('sass', this.customCssTextarea.value)

    const afterAjaxCompleted = () => {
      compilationButton.classList.remove(COMPILING_CSS_CLASS)
      compilationButton.removeAttribute('disabled')
    }

    Rails.ajax({
      type: 'PUT',
      url: compilationButton.dataset.compilationUrl,
      data: formData,
      success: (response) => {
        afterAjaxCompleted()
        this.setCustomCss(response.css)
        showFlash(I18n.editor.sass_compilation_success, false)
      },
      error: (response) => {
        afterAjaxCompleted()
        showFlash(I18n.editor.sass_compilation_error + '\n' + response.error, true)
      }
    })
  }

  setCustomCss (value) {
    this.customCssStyle.innerText = value
  }

  setRootVariable (variableName, value) {
    if (variableName.includes('font')) value = `"${value}"`
    const property = `--site-${variableName}`
    document.documentElement.style.setProperty(property, value)
    this.rootVariables[property] = value
  }

  setRootVariablesDeep () {
    const rules = this.siteStyleSheet.cssRules || this.siteStyleSheet.rules
    for (const rule of rules) {
      if (rule.selectorText === ':root') {
        for (const variableName in this.rootVariables) {
          rule.style.setProperty(variableName, this.rootVariables[variableName])
        }
        this.rootVariables = {}
        break
      }
    }
  }
}

export default function (inspector) {
  return new StyleManager(inspector)
}
