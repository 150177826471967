import ClipboardJS from 'clipboard'

document.addEventListener("turbolinks:load", function() {
  const clipboard = new ClipboardJS('[data-copy]')
  const element = $('[data-copy]')
  if (element.tooltip) {
    element.tooltip({
      placement: 'bottom',
      title: function () {
        return this.dataset.copy
      },
      trigger: 'manual'
    })
  }

  clipboard.on('success', function (e) {
    const $trigger = $(e.trigger)
    if ($trigger.tooltip) {
      $trigger.tooltip('show')
      setTimeout(function () {
        $trigger.tooltip('hide')
      }, 600)
    }
  })
})
