import Bridge from './bridge.js'
import Rails from '@rails/ujs'
import { DirectUpload } from "@rails/activestorage"
import Uploader from '../../page_editor/direct_uploader.js'


const placeholderImageRegex = /\/placeholders\//

const acceptedTypes = [
  'image/gif',
  'image/jpeg',
  'image/jpg',
  'image/png'
]

let assetHost = process.env.ASSET_HOST
if (!['80', '443', ''].includes(window.location.port)) assetHost += `:${window.location.port}`
const activeStorageImageRegex = /\/active_storage\//

export default class ImageBridge extends Bridge {
  init () {
    this.restoreImageFieldsLtn = (e) => this.restoreImageFields()
    window.addEventListener('worksaver:restored', this.restoreImageFieldsLtn)
    this.imagesContainer = this.form.querySelector('#page-images')
  }

  deinit() {
    window.removeEventListener('worksaver:restored', this.restoreImageFieldsLtn)
  }

  restoreImageFields() {
    this.canvas.querySelectorAll('img').forEach((img)=>{
      const src = img.dataset.src || img.src
      img.src = src
      img.removeAttribute('data-src')
    })
  }

  onActionsSet () {
    this.callAction('registerBeforeSubmitHook', { func: this.setImageSources.bind(this) })
    if (!this.imagesContainer) return
    // pages only from here
    this.callAction('registerBeforeSubmitHook', { func: this.setImageFields.bind(this) })
  }

  setupBackgroundImage (block) {
    const backgroundImage = block.style.backgroundImage
    const noImage = backgroundImage === 'none' || !backgroundImage.includes('url')

    if (noImage) {
      this.bgControlLabel.innerHTML = I18n.editor.image_empty_label
      this.controls.backgroundImageRemove.style.display = 'none'
      if (block.classList.contains('header')) block.classList.add('opacity-0')
    } else {
      this.bgControlLabel.innerHTML = backgroundImage.slice(4,-1).replace(/["']/g, "").split('/').pop()
      block.classList.remove('opacity-0')
      this.controls.backgroundImageRemove.style.display = 'block'
    }
  }

  uploadImage (input, cb) {
    if (!acceptedTypes.includes(input.files[0].type)) {
      showFlash(I18n.editor.images_only, true)
      input.value = null
      return
    }

    const myUploader = new Uploader(input.files[0], input.dataset.directUploadUrl)

    const directUpload = new DirectUpload(input.files[0], input.dataset.directUploadUrl, myUploader)

    const objectUrl = URL.createObjectURL(input.files[0])

    this.callAction('lock')

    directUpload.create((error, blob) => {
      this.callAction('unlock')
      if (error) {
        showFlash(I18n.editor.image_upload_error, true)
      } else {
        Rails.ajax({
          type: "POST",
          url: "/admin/gallery/attach_image",
          data: `blob_id=${blob.signed_id}`,
          success: (response) => {
            cb(`${window.location.protocol}//${assetHost}${response.path}`, objectUrl)
            //this.changeCb()
          },
          error: (response) => {
            showFlash(I18n.editor.image_attach_error, true)
          }
        })
      }
    })

    input.value = null
  }

  setImageSources () {
    this.canvas.querySelectorAll('img[data-src]').forEach((img) => {
      img.src = img.dataset.src || img.src
      img.removeAttribute('data-src')
    })
  }

  setImageFields () {
    this.imagesContainer.textContent = ''
    const signedBlobIds = this.extractBlobsFromElement(this.canvas)
    signedBlobIds.forEach((signedBlobId) => { this.appendImageField(signedBlobId) })
    //this.changeCb()
  }

  extractBlob (url) {
    if (!activeStorageImageRegex.test(url)) return
    return url.match(window.Helpers.activeStorageBlobExtractor)[1]
  }

  appendImageField (signedBlobId) {
    const hiddenField = document.createElement('input')
    hiddenField.name = this.imagesContainer.dataset.name
    hiddenField.setAttribute('type', 'hidden')
    hiddenField.setAttribute('value', signedBlobId)
    this.imagesContainer.appendChild(hiddenField)
  }

  extractBlobsFromElement (element) {
    const signedBlobIds = []
    let signedBlobId

    if(element.tagName === 'IMG') {
      signedBlobId = this.extractBlob(element.src)
    } else {
      signedBlobId = this.extractBlob(element.style.backgroundImage)
    }
    if (signedBlobId) signedBlobIds.push(signedBlobId)


    element.querySelectorAll('img').forEach((img)=>{
      signedBlobId = this.extractBlob(img.src)
      if (signedBlobId) signedBlobIds.push(signedBlobId)
    })

    element.querySelectorAll('div[style]').forEach((div) => {
      signedBlobId = this.extractBlob(div.style.backgroundImage)
      if (signedBlobId) signedBlobIds.push(signedBlobId)
    })
    return signedBlobIds
  }
}