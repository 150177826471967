import { Controller } from "stimulus"

const TTL = 1209600000 // 2 weeks millisecs

export default class extends Controller {
  static targets = ["savable", "dialog", "lockable", "updatedAt"]

  connect () {
    this.isActive = false;
    this.pathname = window.location.pathname
    this.savedAtKey = `${this.pathname}[saved_at]`

    this.visibilitychangeLtn = (e)=> {
      if (document.hidden) {
        this.saveAll()
      }
    }
    this.submitLtn = (e) => this.deactivate()
    this.saveAllLtn = (e) => this.saveAll()
    this.ajaxSuccessLtn = (e) => this.destroyAll()

    this.element.addEventListener('submit', this.submitLtn, false)
    this.element.addEventListener('ajax:success', this.ajaxSuccessLtn, false)
    document.addEventListener("visibilitychange", this.visibilitychangeLtn, false);
    window.addEventListener('beforeunload', this.saveAllLtn, false)
    window.addEventListener('popstate', this.saveAllLtn, false)
    document.addEventListener('turbolinks:before-visit', this.saveAllLtn, false)

    if (!this._savedWorkIsValid()) {
      this.destroyAll()
    }

    if (this.savableTargets.some((savable)=>this._exists(savable))) {
      this.dialogTarget.style.display = 'block'
      this.dialogTarget.scrollIntoView({block: 'center'})
      this._lockAll()
    }
  }

  _savedWorkIsValid () {
    if (!this.hasUpdatedAtTarget) return true
    const savedAt = window.localStorage.getItem(this.savedAtKey)
    return !savedAt || this.updatedAtTarget.dataset.value < savedAt
  }

  _getKey (savable) {
    return `${this.pathname}[${savable.id}]`
  }

  _exists (savable) {
    return Boolean(window.localStorage.getItem(this._getKey(savable)))
  }

  _restore (savable) {
    const restoredContent = window.localStorage.getItem(this._getKey(savable));
    if (!restoredContent) return
    if (savable.tagName === 'INPUT') {
      savable.value = restoredContent
    } else if (savable.classList.contains('summernote')) {
      $(savable).summernote('code', restoredContent)
    } else {
      savable.innerHTML = restoredContent
    }
  }

  _lockAll () {
    this.lockableTargets.forEach((lockable)=>{
      lockable.classList.add('locked')
    })
  }

  _unlockAll () {
    this.lockableTargets.forEach((lockable)=>{
      lockable.classList.remove('locked')
    })
  }

  _save (savable) {
    if (savable.tagName === 'INPUT' || savable.classList.contains('summernote')) {
      window.localStorage.setItem(this._getKey(savable), savable.value.trim());
      return
    } 
    window.localStorage.setItem(this._getKey(savable), savable.innerHTML.trim());
  }

  _remove (savable) {
    window.localStorage.removeItem(this._getKey(savable))
  }

  _removeExpiredSavables () {
    const keysByPathname = {}
    const pathnamesToRemove = new Array()
    const now = Date.now()

    for (const key in window.localStorage){
      if (!key.startsWith('/admin')) continue

      const pathname = key.split('[')[0]
      if (!keysByPathname[pathname]) keysByPathname[pathname] = new Array()
      keysByPathname[pathname].push(key)

      if (key.includes('saved_at')) {
        const value = window.localStorage.getItem(key)
        if (!value) continue

        const time = Date.parse(value)
        if (now - time >= TTL) pathnamesToRemove.push(pathname)
      }
    }

    pathnamesToRemove.forEach((pathname) => {
      keysByPathname[pathname].forEach(
        (key) => window.localStorage.removeItem(key)
      )
    })
  }

  destroyAll () {
    this.savableTargets.forEach((savable)=>{
      this._remove(savable)
    })
    window.localStorage.removeItem(this.savedAtKey)
  }

  deactivate () {
    this.isActive = false
  }

  activate (e) {
    this.isActive = true
  }

  dismiss () {
    this.dialogTarget.style.display = 'none'
    this.destroyAll()
    this._unlockAll()
  }

  restoreAll () {
    this.savableTargets.forEach((savable)=>{
      this._restore(savable)
    })
    this.dialogTarget.style.display = 'none'
    this._unlockAll()
    window.dispatchEvent(new Event('worksaver:restored'))
  }

  saveAll () {
    if (!this.isActive) return
    this._removeExpiredSavables()
    this.savableTargets.forEach((savable)=>{
      this._save(savable)
    })
    window.localStorage.setItem(this.savedAtKey, new Date().toISOString());
  }

  disconnect () {
    this.element.removeEventListener('submit', this.submitLtn, false)
    this.element.removeEventListener('ajax:success', this.ajaxSuccessLtn, false)
    document.removeEventListener("visibilitychange", this.visibilitychangeLtn, false);
    window.removeEventListener('beforeunload', this.saveAllLtn, false)
    window.removeEventListener('popstate', this.saveAllLtn, false)
    document.removeEventListener('turbolinks:before-visit', this.saveAllLtn, false)
  }
}
