import Rails from '@rails/ujs'
import Bridge from './bridge.js'
import { BlockManager } from '../../page_editor/block_manager.js'

export default class CustomBlockBridge extends Bridge {
  init () {
    this._super(this, 'init')
    this.matchables =  this.form.querySelector('.block-custom-block [data-match-section]')

    this.syncClosestBlockLtn = (e) => {
      if (e.data.from === CustomBlockBridge) return
      this.syncClosestBlock(e.target)
    }

    this.fillReusableBlocksLtn = (e) => this.fillReusableBlocks()
    this.afterBlocksConfiguredLtn = (e) => { this.fillReusableBlocks(); this.callAction('decrementAsyncLoads')}
    this.canvas.addEventListener('block:changed', this.syncClosestBlockLtn)
    this.canvas.addEventListener('block:removed', this.syncClosestBlockLtn)
    this.canvas.addEventListener('blocks:configured', this.afterBlocksConfiguredLtn)
    window.addEventListener('worksaver:restored', this.fillReusableBlocksLtn)
    this.removeCustomBlockLtn = (e) => this.removeCustomBlock(e.data.blockId)
    this.editor.addEventListener('customblock:remove', this.removeCustomBlockLtn)
  }

  onActionsSet () {
    this.callAction('incrementAsyncLoads')
    this.callAction('registerBeforeSubmitHook', { func: this.setBlockTextAsData.bind(this) })
  }

  deinit () {
    this.canvas.removeEventListener('block:changed', this.syncClosestBlockLtn)
    this.canvas.removeEventListener('block:removed', this.syncClosestBlockLtn)
    this.canvas.removeEventListener('blocks:configured', this.afterBlocksConfiguredLtn)
    window.removeEventListener('worksaver:restored', this.fillReusableBlocksLtn)
    this.editor.removeEventListener('customblock:remove', this.removeCustomBlockLtn)
  }

  get section () {
    return this.selection.section
  }

  set section (value) {
    return this.selection.section = value
  }

  get blockId () {
    return this.selection.section.dataset.configBlockId
  }

  matchSections () {
    this.matchables.forEach((matchable) => {
      matchable.classList.toggle(
        'd-none', this.selection.sectionMatches(matchable)
      )
    })
  }

  syncClosestBlock (element) {
    if (this.selection && this.section.dataset.configBlockId) {
      this.section.dataset.configBlockTime = Helpers.currentTime36()
      this.originalBlock = this.section.cloneNode(true)
      BlockManager.setBlock(this.blockId, this.controls.blockName.value, this.originalBlock)

      this.canvas.querySelectorAll(
        `[data-config-block-id="${this.blockId}"]`
      ).forEach((node) => {
        if (node !== this.section) {
          node.outerHTML = this.section.outerHTML
        }
      })
    }
  }

  setupSyncBlock () {
    this.section.dataset.configId = 0
    this.section.dataset.blockName = this.originalBlock.dataset.blockName
    this.controls.blockName.disabled = true 
    window.Helpers.setControlValue(this.controls.blockReusable, true)
    this.changeCb({ from: CustomBlockBridge })
  }

  setupUnsyncBlock () {
    this.section = window.Helpers.replaceElement(this.section, this.originalBlock.outerHTML)
    this.setupSyncBlock()
  }

  setupRegularBlock () {
    if (this.section.dataset.configBlockId) {
      this.removeConfigAttributes(this.section)
      this.changeCb({ from: CustomBlockBridge })
    }
    this.controls.blockName.removeAttribute('disabled')
    window.Helpers.setControlValue(this.controls.blockReusable, false)
  }

  setupCustomBlock (synced) {
    if (this.blockId && !this.originalBlock) {
      this.originalBlock = BlockManager.getBlock(this.blockId)
    }

    if (this.originalBlock) {
      this.controls.blockName.value = this.originalBlock.dataset.blockName
    } else {
      this.controls.blockName.value = ""
    }

    if (synced || (this.originalBlock && this.originalBlock.dataset.configBlockTime === this.section.dataset.configBlockTime)) {
      this.setupSyncBlock()
    } else if (this.originalBlock) {
      this.setupUnsyncBlock()
    } else {
      this.setupRegularBlock()
    }
  }


  createBlock () {
    if (this.section.dataset.configId || this.section.querySelector('[data-config-id]')) {
      showFlash(I18n.editor.special_block_error, true)
      return
    }

    const name = this.controls.blockName.value.trim()
    if (!name) {
      showFlash(I18n.editor.block_name_error, true)
      return
    }

    this.callAction('lock')
    this.section.dataset.configId = 0
    this.section.dataset.configType = 'block'

    Rails.ajax({
      type: 'POST',
      url: "/admin/blocks",
      data: new URLSearchParams({
        "block[name]": name,
        "block[text]": this.section.outerHTML
      }).toString(),
      success: (response) => {
        this.callAction('unlock')
        this.section.dataset.configBlockTime = response.time
        this.section.dataset.configBlockId = response.id
        this.originalBlock = this.section.cloneNode(true)
        this.originalBlock.dataset.blockName = name
        BlockManager.createBlock(response.id, this.originalBlock, this.editor)
        showFlash(response.notice, false)
        this.callAction('fillBlock')
      },
      error: (_) => {
        this.section.removeAttribute('data-config-id')
        this.section.removeAttribute('data-config-type')
        this.callAction('unlock')
      }
    })
  }

  removeConfigAttributes (node) {
    node.removeAttribute('data-config-id')
    node.removeAttribute('data-config-type')
    node.removeAttribute('data-config-block-text')
    node.removeAttribute('data-config-block-sync')
    node.removeAttribute('data-config-block-id')
    node.removeAttribute('data-config-block-time')
    node.removeAttribute('data-block-name')
  }

  removeCustomBlock (blockId) {
    if (!window.confirm(I18n.editor.confirm_reusable_block_deletion)) return
    this.callAction('lock')

    Rails.ajax({
      type: 'DELETE',
      url: `/admin/blocks/${blockId}`,
      success: (response) => {
        this.callAction('unlock')
        BlockManager.deleteBlock(blockId)
        this.canvas.querySelectorAll(
          `[data-config-block-id="${blockId}"]`
        ).forEach((node) => {
          this.removeConfigAttributes(node)
        })
      },
      error: (_) => {
        this.callAction('unlock')
      }
    })
  }

  setBlockTextOf(block) {
    block.removeAttribute('data-config-block-text')
    block.dataset.configBlockText = block.outerHTML
  }

  setBlockTextAsData () {
    this.canvas.querySelectorAll(
      '[data-config-id][data-config-block-id]'
    ).forEach((block) => {
      block.dataset.configType = 'block'
      this.setBlockTextOf(block)
    })
  }

  fillReusableBlocks () {
    let changed = false
    this.canvas.querySelectorAll(
      '[data-config-block-id],[data-config-block-text]'
    ).forEach((block) => {
      const blockId = block.dataset.configBlockId
      const originalBlock = (blockId ? BlockManager.getBlock(blockId) : null)

      if (originalBlock) {
        const originalTime = originalBlock.dataset.configBlockTime
        const time = block.dataset.configBlockTime

        if (block.dataset.configBlockText && originalTime && time && time > originalTime) {
          block = Helpers.replaceElement(block, block.dataset.configBlockText)
          BlockManager.setBlock(block.dataset.configBlockId, originalBlock.dataset.blockName, block)
        } else {
          block = Helpers.replaceElement(block, originalBlock.outerHTML)
        }

        block.dataset.configId = 0
        this.setBlockTextOf(block)
        block.dataset.configType = 'block'
        changed = true
      } else if (block.dataset.configBlockText) {
        block = Helpers.replaceElement(block, block.dataset.configBlockText)
        this.removeConfigAttributes(block)
        changed = true
      } else {
        this.removeConfigAttributes(block)
        changed = true
      }
    })
    
    if (changed) this.canvas.dispatchEvent(new Event('canvas:changed'))
  }
 
}