import Rails from '@rails/ujs'
import Bridge from './bridge.js'

export default class BlogBridge extends Bridge {
  init () {
    this.cache = {}
    this.onBlockAddedLtn = (e) => this.onBlockAdded(e.target)
    this.injectArticlesInBlocksLtn = (e) => this.injectArticlesInBlocks()
    this.canvas.addEventListener('block:added', this.onBlockAddedLtn)
    this.canvas.addEventListener('blocks:configured', this.injectArticlesInBlocksLtn)
  }

  deinit () {
    this.canvas.removeEventListener('block:added', this.onBlockAddedLtn)
    this.canvas.removeEventListener('blocks:configured', this.injectArticlesInBlocksLtn)
  }

  injectArticlesInBlocks () {
    const blogs = this.canvas.querySelectorAll('.blog')
    let blogsCount = blogs.length
    blogs.forEach((blog) => {
      this.callAction('incrementAsyncLoads')
      this.injectArticles(
        blog,
        (0 === --blogsCount ? () => this.callAction('decrementAsyncLoads') : null)
      )
    })
  }

  onBlockAdded (block) {
    if (!block.querySelector('.blog')) return
    this.injectArticles(block)
  }

  applyShow(type, articlesContainer) {
    if (articlesContainer.dataset.configClass === 'articles-full') return
    const show = articlesContainer.getAttribute(`data-config-show-${type}`) !== 'false'
    articlesContainer.querySelectorAll(`.articles-${type}`).forEach(
      (element) => {
        if (show) {
          element.classList.remove('d-none')
        } else {
          element.classList.add('d-none')
        }
      }
    )
  }

  applyArticlesLink (articlesContainer) {
    if (articlesContainer.dataset.configClass === 'articles-full') return
    const linkName = articlesContainer.dataset.configLink
    articlesContainer.querySelectorAll('.articles-link').forEach(
      (link) => {
        link.innerHTML = linkName
        link.style.display = !!linkName ? 'block' : 'none'
      }
    )
  }

  updateClass (block, articlesContainer, klass) {
    block.classList.add(klass)
    articlesContainer.dataset.configClass = klass
    this.controls['data-config-class'].value = klass
  }

  updatePagination (articlesContainer) {
    const pagination = articlesContainer.querySelector('nav.pagination')
    if (!pagination) return
    const all = articlesContainer.dataset.configAll === 'true'
    const infiniteScroll = articlesContainer.dataset.configInfiniteScroll === 'true'
    if (!all || infiniteScroll) {
      pagination.style.display = 'none'
    } else {
      pagination.style.removeProperty('display')
    }
  }

  updateLoadingStatus (articlesContainer) {
    const loadingStatus = articlesContainer.querySelector('.page-load-status')
    if (!loadingStatus) return
    const all = articlesContainer.dataset.configAll === 'true'
    const infiniteScroll = articlesContainer.dataset.configInfiniteScroll === 'true'

    if (all && infiniteScroll) {
      loadingStatus.classList.remove('d-none')
    } else {
      loadingStatus.classList.add('d-none')
    }
  }

  injectArticles(blog, cb) {
    const articlesContainer = blog.querySelector('.articles')
    const limit = articlesContainer.dataset.configAll === 'true' ? undefined : articlesContainer.dataset.configLimit

    this.fetchArticles(
      articlesContainer.dataset.configClass,
      limit,
      articlesContainer.dataset.configCategory,
      (articles) => {
        articlesContainer.innerHTML = articles
        this.applyArticlesLink(articlesContainer)
        this.applyShow('title', articlesContainer)
        this.applyShow('text', articlesContainer)
        this.applyShow('image', articlesContainer)
        this.applyShow('date', articlesContainer)
        this.updatePagination(articlesContainer)
        this.updateLoadingStatus(articlesContainer)
        if (cb) cb()
      }
    )
  }

  fetchArticles (articlesClass, articlesLimit, articlesCategory, cb) {
    const params = new URLSearchParams(window.location.search.slice(1))
    params.set('articles_only', 1)

    if (articlesCategory) {
      params.set('articles_category', articlesCategory)
    }

    if (articlesLimit) {
      params.set('limit', articlesLimit)
    } else {
      params.set('all', 1)
    }

    params.set('articles_class', articlesClass)
    const url = `/admin/articles?${params}`

    if (this.cache[url]) {
      cb(this.cache[url])
      return
    }

    Rails.ajax({
      type: "GET",
      url: url,
      success: (response) => {
        this.cache[url] = response.html
        cb(response.html)
      }
    })
  }
}