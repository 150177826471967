const HIGHLIGHT_PADDING = 4
const HOVER_CLASS = 'page-editor-highlight-hover'
const SELECT_CLASS = 'page-editor-highlight-select'
const SECTION_CLASS = 'page-editor-highlight-section'
import selection from './selection.js'

class HighlightManager {
  constructor (canvas, afterSelectCb) {
    this.afterSelectCb = afterSelectCb
    this.canvas = canvas

    this.createElements()

    this.repositionLtn = (e) => this.reposition()
    this.showLtn = (e) => this.show(e.target)
    this.selectLtn = (e) => this.select(e.target)

    this.canvas.addEventListener('input', this.repositionLtn, true)
    this.canvas.addEventListener('mouseover', this.showLtn, true)
    this.canvas.addEventListener('click', this.selectLtn, true)
    window.addEventListener('scroll', this.repositionLtn, true)
  }

  dispose () {
    this.canvas.removeEventListener('click', this.selectLtn, true)
    window.removeEventListener('scroll', this.repositionLtn, false)
  }

  createElements () {
    this.hoverElement = document.createElement('div')
    this.hoverElement.classList.add(HOVER_CLASS)
    this.canvas.parentNode.appendChild(this.hoverElement)

    this.selectElement = document.createElement('div')
    this.selectElement.classList.add(SELECT_CLASS)
    this.canvas.parentNode.appendChild(this.selectElement)

    this.sectionElement = document.createElement('div')
    this.sectionElement.classList.add(SECTION_CLASS)
    this.canvas.parentNode.appendChild(this.sectionElement)
  }

  selected () {
    return this.selectTarget
  }

  select (target) {
    if (this.canvas === target) {
      return
    }

    this.selectTarget = this.highlightElement(target)
    this.sectionTarget = this.highlightSection(target)
    this.selectElement.style.display = 'block'
    this.hoverElement.style.display = 'none'

    if (this.sectionTarget === this.selectTarget) {
      this.sectionElement.style.display = 'none'
    } else {
      this.sectionElement.style.display = 'block'
    }

    this.reposition()
    this.afterSelectCb(
      selection(this.selectTarget, this.sectionTarget)
    )
  }

  show (target) {
    if (target === this.canvas) {
      return
    }

    this.hoverTarget = this.highlightElement(target)
    this.hoverElement.style.display = 'block'

    this.reposition()
  }

  highlightSection (target) {
    return target.closest('#canvas > *')
  }

  highlightElement (target) {
    const ancestor = target.closest('.site-richtext, [data-countdown], a:not(.btn), svg')
    return ancestor ? ancestor : target
  }

  hide () {
    this.hoverElement.style.display = 'none'
    this.selectElement.style.display = 'none'
  }

  reposition () {
    this.repositionElement(this.hoverElement, this.hoverTarget)
    this.repositionElement(this.selectElement, this.selectTarget)
    this.repositionElement(this.sectionElement, this.sectionTarget)
  }

  repositionElement (element, target) {
    if (!target) {
      return
    }

    const blockRect = target.getBoundingClientRect()

    element.style.height = blockRect.height + HIGHLIGHT_PADDING * 2 + 'px'
    element.style.width = blockRect.width + HIGHLIGHT_PADDING * 2 + 'px'

    element.style.left = blockRect.left - HIGHLIGHT_PADDING + 'px'
    element.style.top = blockRect.top - HIGHLIGHT_PADDING  + 'px'
  }
}

export default function (canvas, afterSelectCb) {
  return new HighlightManager(canvas, afterSelectCb)
}
