import { Controller } from "stimulus"

import styleManager from "page_editor/style_manager"
import blockManager from "page_editor/block_manager"
import highlightManager from "page_editor/highlight_manager"
import historyManager from "page_editor/history_manager"
import inspectorManager from "page_editor/inspector_manager"

export default class extends Controller {
  static targets = ["canvas", "editor", "form", "input", "inspector", "style", "undo", "redo"]

  connect() {
    const canvas = this.canvasTarget

    if (!this.hasInputTarget) {
      return
    }

    this.inputTarget.value = this.canvasTarget.innerHTML.trim()
    this.styleManager = styleManager(this.inspectorTarget)
    this.blockManager = blockManager(canvas)
    this.highlightManager = highlightManager(canvas, (selection) => this.afterBlockSelectCb(selection))
    this.historyManager = historyManager(this.formTarget, canvas, this.undoTarget, this.redoTarget, () => this.afterUndoOrRedoCb())
    this.inspectorManager = inspectorManager(this.editorTarget, canvas, this.inspectorTarget, this.formTarget, () => this.afterBlockEditCb(), () => this.saveCb())

    this.highlightManager.hide()
    this.inspectorManager.showPage()
  }

  disconnect() {
    if (!this.hasInputTarget) {
      return
    }

    this.highlightManager.dispose()
    this.inspectorManager.dispose()
    this.styleManager.dispose()
    this.historyManager.dispose()
  }

  changeInput() {
    if (this.inputTarget.value.trim() !== this.canvasTarget.innerHTML.trim()) {
      this.inputTarget.value = this.canvasTarget.innerHTML.trim()
      this.inputTarget.dispatchEvent(new Event('change'))
    }
  }

  afterUndoOrRedoCb() {
    this.highlightManager.hide()
    this.inspectorManager.showPage()
  }

  afterBlockEditCb() {
    this.changeInput()
    this.highlightManager.reposition()
  }

  afterBlockSelectCb(selection) {
    this.inspectorManager.showBlock(selection)
    this.setupActionButtons()
  }

  showPageInspector() {
    this.inspectorManager.showPage()
  }

  setupActionButtons() {
    const firstSibling = !this.blockManager.canMove(this.inspectorManager.selection(), 'up')
    const lastSibling = !this.blockManager.canMove(this.inspectorManager.selection(), 'down')
    const noParent = this.inspectorManager.selection().parentNode === this.canvasTarget

    this.inspectorTarget.classList.toggle('page-editor-first-sibling', firstSibling)
    this.inspectorTarget.classList.toggle('page-editor-last-sibling', lastSibling)
    this.inspectorTarget.classList.toggle('page-editor-no-parent', noParent)

    this.inspectorTarget.querySelector('.block-actions-up').disabled = firstSibling
    this.inspectorTarget.querySelector('.block-actions-down').disabled = lastSibling
    this.inspectorTarget.querySelector('.block-actions-parent').disabled = noParent
  }

  appendBlock(e) {
    this.blockManager.append(e.currentTarget.dataset.name)
    this.changeInput()
  }

  moveBlockUp() {
    this.blockManager.moveUp(this.highlightManager.selected())
    this.highlightManager.reposition()
    this.setupActionButtons()
    this.changeInput()
  }

  moveBlockDown() {
    this.blockManager.moveDown(this.highlightManager.selected())
    this.highlightManager.reposition()
    this.setupActionButtons()
    this.changeInput()
  }

  copyBlock() {
    const target = this.blockManager.copy(this.highlightManager.selected())
    if (target.nodeName === 'LI'
      && target.classList.contains('nav-item')
      && target.querySelector('a.nav-link')) {
      this.highlightManager.select(target.querySelector('a.nav-link'))
    } else if (target.parentNode) this.highlightManager.select(target)
    this.changeInput()
  }

  deleteBlock() {
    this.blockManager.delete(this.highlightManager.selected())
    this.highlightManager.hide()
    this.inspectorManager.showPage()
    this.changeInput()
  }

  removeCustomBlock(e) {
    const event = new Event('customblock:remove')
    event.data = { blockId: e.currentTarget.dataset.id }
    this.editorTarget.dispatchEvent(event)
  }

  selectBlockParent() {
    const target = this.blockManager.actionTarget(this.highlightManager.selected().parentNode)
    this.highlightManager.select(target)
  }

  markAsDraft() {
    this.formTarget.dataset.draft = true
  }

  setStyleVariable(e) {
    const variableName = e.target.id
    this.styleManager.setRootVariable(variableName, e.target.value)
  }

  saveCb() {
    this.styleManager.setRootVariablesDeep()
  }

  compileCustomCss(e) {
    this.styleManager.compileCustomCss(e)
  }
}
