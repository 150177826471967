import diff from './diff'

const HISTORY_LIMIT = 20

class HistoryManager {
  constructor (form, canvas, undoTarget, redoTarget, afterUndoOrRedoCb) {
    this.form = form
    this.canvas = canvas
    this.undoTarget = undoTarget
    this.redoTarget = redoTarget
    this.afterUndoOrRedoCb = afterUndoOrRedoCb
    this.stack = []
    this.cursor = 0
    this.setupHistoryActions()
    this.initLtn = () => {
      this.init()
      this.canvas.removeEventListener('canvas:loaded', this.initLtn)
    }
    this.canvas.addEventListener('canvas:loaded', this.initLtn)
    this.commitDebouncer = makeDebouncer(800)
  }

  init () {
    this.lastContent = this.canvas.innerHTML.trim()
    this.undoLtn = () => this.undo()
    this.redoLtn = () => this.redo()
    this.commitLtn = () => this.commit()
    this.undoTarget.addEventListener('click', this.undoLtn)
    this.redoTarget.addEventListener('click', this.redoLtn)

    this.canvas.addEventListener('block:changed', this.commitLtn)
    this.canvas.addEventListener('block:added', this.commitLtn)
    this.canvas.addEventListener('block:removed', this.commitLtn)
    this.canvas.addEventListener('block:cloned', this.commitLtn)
    this.canvas.addEventListener('block:moved', this.commitLtn)
  }

  dispose () {
    this.undoTarget.removeEventListener('click', this.undoLtn)
    this.redoTarget.removeEventListener('click', this.redoLtn)

    this.canvas.removeEventListener('block:changed', this.commitLtn)
    this.canvas.removeEventListener('block:added', this.commitLtn)
    this.canvas.removeEventListener('block:removed', this.commitLtn)
    this.canvas.removeEventListener('block:cloned', this.commitLtn)
    this.canvas.removeEventListener('block:moved', this.commitLtn)
  }

  setupHistoryActions () {
    this.undoTarget.disabled = (this.cursor <= 0)
    this.undoTarget.classList.toggle('text-muted', this.undoTarget.disabled)
    this.redoTarget.disabled = (this.cursor >= this.stack.length -1)
    this.redoTarget.classList.toggle('text-muted', this.redoTarget.disabled)
  }

  commit () {
    this.commitDebouncer(() => {
      const currentContent = this.canvas.innerHTML.trim()
      if (this.lastContent === currentContent) return
      const diffs = diff(this.lastContent, currentContent)

      this.stack[++this.cursor] = diffs
      this.stack.length = this.cursor + 1

      if (this.stack.length >= HISTORY_LIMIT) {
        this.stack.shift()
        this.cursor = this.stack.length
      }

      this.lastContent = currentContent
      this.setupHistoryActions()
    })
  }

  dispatchEvent () {
    this.canvas.dispatchEvent(new Event('canvas:changed', { bubbles: true }))
    this.afterUndoOrRedoCb()
  }

  undo () {
    if (this.cursor < 0) return
    this.canvas.innerHTML = this.lastContent = applyDiffs(this.lastContent, this.stack[this.cursor--], -1)
    this.dispatchEvent()
    this.setupHistoryActions()
  }

  redo () {
    if (this.cursor === this.stack.length) return
    this.canvas.innerHTML = this.lastContent = applyDiffs(this.lastContent, this.stack[++this.cursor], 1)
    this.dispatchEvent()
    this.setupHistoryActions()
  }
}

function applyDiffs (origin, diffs, direction) {
  const DELETE = direction * diff.DELETE
  let result = '', cursor = 0

  diffs.forEach((d) => {
    if (!isNaN(d)) {
      result += origin.substring(cursor, cursor + d)
      cursor += d
    } else if (d[0] == DELETE) {
      cursor += d[1].length
    } else {
      result += d[1]
    }
  })

  return result
}

function makeDebouncer(duration) {
  function debouncer (action) {
    if (this.task) clearTimeout(this.task)
    this.task = setTimeout(() => {
      this.task = undefined
      action.call()
    }, duration)
  }
  return debouncer
}


export default function (form, canvas, undoTarget, redoTarget, afterUndoOrRedoCb) {
  return new HistoryManager(form, canvas, undoTarget, redoTarget, afterUndoOrRedoCb)
}