import Control from './control.js'

const attributesCapitalize = {
  id: 'Id',
  class: 'Class'
}

export default class AdvancedControl extends Control {
  static bridgeId = 'border'

  setValue (e) {
    this[`set${attributesCapitalize[this.attribute]}`](e)
    this.changeCb()
  }

  get event () {
    return this.control.dataset.controlEvent || 'input'
  }

  setId () {
    if (/\W/.test(this.control.value)) {
      this.control.value = this.block.id
    } else if (this.control.value === '') {
      this.block.removeAttribute('id')
    } else if (document.getElementById(this.control.value)) {
      this.onError()
    } else{
      this.block.id = this.control.value
    }
  }

  setClass () {
    this.control.value = this.control.value.replace(/[^0-9a-z\s_\-]/gi, '')
    this.block.setAttribute('class', this.control.value)
  }
}