import Control from './control.js'

export default class StyleControl extends Control {
  _super(that, method, args) {
    StyleControl.prototype[method].apply(that, args || [])
  }

  setup () {
    this.value = this.selection.blockStyle[this.attribute]
  }

  setValue (e) {
    this.block.style[this.attribute] = this.value
    this.changeCb()
  }
}