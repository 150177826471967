document.addEventListener('turbolinks:load', function () {
  document.querySelectorAll('[data-dismiss=modal]').forEach((button) => {
    button.addEventListener('click', function () {
      $(button.closest('.modal')).modal('hide')
    })
  })

  document.querySelectorAll('.modal.show').forEach((modal) => {
    $(modal).modal('show')
  })
})