import "summernote/dist/summernote-bs4"
import "summernote/dist/summernote-bs4.css"
import "summernote/dist/lang/summernote-fr-FR"
import Rails from "@rails/ujs"
import { DirectUpload } from "@rails/activestorage"
import { createPopper } from 'popper.js'

const acceptedTypes = ["image/gif", "image/jpeg", "image/jpg", "image/png"]

const addSummernote = (type) => {
  let toolbar, changed = false

  if (type === "lean") {
    toolbar = [
      ["style", ["style"]],
      ["size", ["fontsize", "height"]],
      ["font", ["bold", "italic", "underline", "strikethrough"]],
      ["script", ["superscript", "subscript"]],
      ["color", ["color"]],
      ["clear", ["clear"]],
      ["para", ["ul", "ol", "paragraph"]],
      ["insert", ["link"]],
      ["view", ["fullscreen", "codeview", "help"]],
    ]
  } else {
    toolbar = [
      ["style", ["style"]],
      ["size", ["fontsize", "height"]],
      ["font", ["bold", "italic", "underline", "strikethrough"]],
      ["script", ["superscript", "subscript"]],
      ["color", ["color"]],
      ["clear", ["clear"]],
      ["para", ["ul", "ol", "paragraph"]],
      ["table", ["table"]],
      ["insert", ["link", "picture"]],
      ["history", ["undo", "redo"]],
      ["view", ["fullscreen", "codeview", "help"]],
    ]
  }

  return (element) => {
    const form = element.closest("form")
    const submitInputs = form.querySelectorAll('input[type="submit"]')
    const lang = document.documentElement.lang

    $(element).summernote({
      toolbar: toolbar,
      disableResizeEditor: true,
      placeholder: element.placeholder,
      lang: lang === "fr" ? "fr-FR" : "en-US",
      callbacks: {
        onInit: function () {
          const ctrlzHelp = element.parentElement.querySelector(
            ".note-editor > :last-child .modal-body :nth-child(8)"
          )
          ctrlzHelp.insertAdjacentHTML(
            "beforebegin",
            `<label style="width: 180px; margin-right: 10px;"><kbd>SHIFT+ENTER</kbd></label><span>${I18n.summernote.line_break}</span>
            <div class="help-list-item"></div>`
          )

          element.parentElement
            .querySelector(".note-editor > :last-child .modal-footer")
            .remove()
        },
        onImageUpload: function (files) {
          uploadFile(files[0], $(this), element, submitInputs)
        },
      },
    })



    $(element).on("summernote.change", function (we, contents, $editable) {
      element.dispatchEvent(new Event("note-change", { bubbles: true }))
      changed = true
    })

    $(element).on('summernote.blur', function() {
      if (changed) {
        element.dispatchEvent(new Event("change", { bubbles: true }))
        changed = false
      }
    });
  };
};

let queueLength = 0;
let assetHost = process.env.ASSET_HOST
if (!['80', '443', ''].includes(window.location.port)) assetHost += `:${window.location.port}`

document.addEventListener("turbolinks:load", function () {
  document.querySelectorAll(".summernote").forEach(addSummernote());
  document.querySelectorAll(".summernote-lean").forEach(addSummernote("lean"))
});

function uploadFile(file, editor, element, submitInputs) {
  if (!acceptedTypes.includes(file.type)) {
    showFlash(I18n.editor.images_only, true)
    return
  }

  const div = document.createElement("div")
  div.setAttribute("align", "center")
  const img = document.createElement("img")
  img.src = URL.createObjectURL(file)

  div.appendChild(img);
  editor.summernote("insertNode", div)
  editor.summernote("pasteHTML", "<div><br/></div>")
  showFileLoadingTooltip(img)
  incrementQueueLength(submitInputs, element)

  const directUpload = new DirectUpload(file, element.dataset.directUploadUrl)

  directUpload.create((error, blob) => {
    if (error) {
      decrementQueueLength(submitInputs, element, editor)
      showFlash(I18n.editor.image_upload_error, true)
    } else {
      Rails.ajax({
        type: "POST",
        url: "/admin/gallery/attach_image",
        data: `blob_id=${blob.signed_id}`,
        success: (response) => {
          img.src = `${window.location.protocol}//${assetHost}${response.path}`
          decrementQueueLength(submitInputs, element, editor)
          hideFileLaodingTooltip(img)
        },
        error: (response) => {
          div.remove();
          decrementQueueLength(submitInputs, element, editor)
          showFlash(I18n.editor.image_attach_error, true)
          hideFileLaodingTooltip(img)
        },
      });
    }
  });
}

function incrementQueueLength(submitInputs, element) {
  if (queueLength == 0) {
    element.dispatchEvent(new Event('note-upload-start'))
    submitInputs.forEach((input) => {
      input.disabled = true
      input.title = I18n.summernote.wait_for_file_upload
      input.dataset.toggle = 'tooltip'
      input.dataset.placement = 'top'
      $(input).tooltip('enable')
    })
  }
  queueLength += 1
}

function decrementQueueLength(submitInputs, element, editor) {
  queueLength -= 1
  if (queueLength > 0) return;
  submitInputs.forEach((input) => {
    $(input).tooltip('dispose')
    input.removeAttribute("disabled")
    input.removeAttribute("title")
    input.removeAttribute("data-toggle")
    input.removeAttribute('data-placement')
  })
  element.value = editor.summernote("code")
  element.dispatchEvent(new Event("note-change"))
  element.dispatchEvent(new Event('note-upload-end'))
}


function showFileLoadingTooltip (fileElement) {
  fileElement.dataset.toggle="popover"
  fileElement.dataset.html = true
  fileElement.dataset.content = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
  $(fileElement).popover('show')
}

function hideFileLaodingTooltip (fileElement) {
  $(fileElement).popover('dispose')
  fileElement.removeAttribute('data-toggle')
  fileElement.removeAttribute('data-html')
  fileElement.removeAttribute('data-content')
}