import Rails from '@rails/ujs'
import Bridge from './bridge.js'

export default class CatalogBridge extends Bridge {
  init () {
    this.cache = {}
    this.injectProductsInBlocksLtn = (e) => this.injectProductsInBlocks()
    this.onBlockAddedLtn = (e) => this.onBlockAdded(e.target)
    this.canvas.addEventListener('block:added', this.onBlockAddedLtn)
    this.canvas.addEventListener('blocks:configured', this.injectProductsInBlocksLtn)
  }

  deinit () {
    this.canvas.removeEventListener('block:added', this.onBlockAddedLtn)
    this.canvas.removeEventListener('blocks:configured', this.injectProductsInBlocksLtn)
  }

  injectProductsInBlocks () {
    const catalogs = this.canvas.querySelectorAll('.catalog')
    let catalogsCount = catalogs.length
    catalogs.forEach((catalog) => {
      this.callAction('incrementAsyncLoads')
      this.injectProducts(
        catalog,
        (0 === --catalogsCount ? () => this.callAction('decrementAsyncLoads') : null)
      )
    })
  }

  updatePagination (productsContainer) {
    const pagination = productsContainer.querySelector('nav.pagination')
    if (!pagination) return
    const all = productsContainer.dataset.configAll === 'true'
    const infiniteScroll = productsContainer.dataset.configInfiniteScroll === 'true'
    if (!all || infiniteScroll) {
      pagination.style.display = 'none'
    } else {
      pagination.style.removeProperty('display')
    }
  }

  updateLoadingStatus (productsContainer) {
    const loadingStatus = productsContainer.querySelector('.page-load-status')
    if (!loadingStatus) return
    const all = productsContainer.dataset.configAll === 'true'
    const infiniteScroll = productsContainer.dataset.configInfiniteScroll === 'true'

    if (all && infiniteScroll) {
      loadingStatus.classList.remove('d-none')
    } else {
      loadingStatus.classList.add('d-none')
    }
  }

  onBlockAdded (block) {
    if (!block.querySelector('.catalog')) return
    this.injectProducts(block)
  }

  applyShow(type, productsContainer) {
    const show = productsContainer.getAttribute(`data-config-show-${type}`) !== 'false'
    productsContainer.querySelectorAll(`.products-${type}`).forEach(
      (element) => {
        if (show) {
          element.classList.remove('d-none')
        } else {
          element.classList.add('d-none')
        }
      }
    )
  }

  applyProductsLink (productsContainer) {
    if (!productsContainer.hasAttribute('data-config-link')) {
      const firstLink = productsContainer.querySelector('.products-link')
      productsContainer.dataset.configLink = !!firstLink ? firstLink.textContent : this.controls['data-config-link'].value
      this.changeCb()
    }

    const linkName = productsContainer.dataset.configLink
    productsContainer.querySelectorAll('.products-link').forEach(
      (link) => {
        link.innerHTML = linkName
        link.style.display = !!linkName ? 'block' : 'none'
      }
    )
  }

  injectProducts(catalog, cb) {
    const productsContainer = catalog.querySelector('.products')
    const limit = productsContainer.dataset.configAll === 'true' ? undefined : productsContainer.dataset.configLimit

    this.fetchProducts(
      productsContainer.dataset.configClass,
      limit,
      productsContainer.dataset.configCategory,
      (products) => {
        productsContainer.innerHTML = products
        this.applyProductsLink(productsContainer)
        this.applyShow('name', productsContainer)
        this.applyShow('price', productsContainer)
        this.applyShow('image', productsContainer)
        this.updatePagination(productsContainer)
        this.updateLoadingStatus(productsContainer)
        if (cb) cb()
      }
    )
  }

  fetchProducts(productsClass, productsLimit, productsCategory, cb) {
    const params = new URLSearchParams(window.location.search.slice(1))
    params.set('products_only', 1)
    params.set('products_class', productsClass)

    if (productsLimit) {
      params.set('limit', productsLimit)
    } else {
      params.set('all', 1)
    }

    if (productsCategory) {
      params.set('products_category', productsCategory)
    }

    const url = `/admin/products?${params}`

    if (this.cache[url]) {
      cb(this.cache[url])
      return
    }

    Rails.ajax({
      type: "GET",
      url: url,
      success: (response) => {
        this.cache[url] = response.html
        cb(response.html)
      }
    })
  }
}