import Control from './control.js'

export default class BorderControl extends Control {
  static bridgeId = 'border'

  setup () {
    if (this.attribute === 'borderSide' && this.control.value === 'All') {
      this.control.checked = false
      this.control.click()
    }
  }

  setValue (e) {
    if (this.attribute === 'borderSide') {
      this.bridge.setup(this.block, this.control.value)
      return
    }

    this.bridge.setValue(this.block)
    this.changeCb()
  }

  get event () {
    if (this.control.dataset.controlEvent) {
      return this.control.dataset.controlEvent
    } else if (this.attribute === 'borderSide') {
      return 'click'
    }
    return 'change'
  }
}