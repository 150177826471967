import { DirectUpload } from "@rails/activestorage"

class Uploader {
  constructor(file, url) {
    this.upload = new DirectUpload(this.file, this.url, this)
    this.uploadProgresses = document.querySelectorAll(".image-upload-progress")
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener("progress",
      event => this.directUploadDidProgress(event))
  }

  directUploadDidProgress(event) {
    let percentage = Math.round(100 * event.loaded / event.total)
    this.uploadProgresses.forEach(uploadProgress => {
      uploadProgress.style.display = 'block'
    })

    this.uploadProgresses.forEach(uploadProgress => {
      uploadProgress.querySelector(".progress-bar").setAttribute("aria-valuenow", `${percentage}`)
      uploadProgress.querySelector(".progress-bar").innerHTML = `${percentage}%`
      uploadProgress.querySelector(".progress-bar").style.width = `${percentage}%`
    })

    if(percentage === 100) {
      this.uploadProgresses.forEach(uploadProgress => {
        uploadProgress.querySelector(".progress-bar").style.width = `${percentage}%`
        uploadProgress.querySelector(".progress-bar").innerHTML = `${percentage}%`
      })
      setTimeout(() => {
        this.uploadProgresses.forEach(uploadProgress => {
          uploadProgress.style.display = 'none'
        })
      }, 1000)
    }
  }
}

export default Uploader