import Bridge from './bridge.js'


export default class SectionFramesBridge extends Bridge {
  setup(block, params) {
    this.updateShapeDivider(block, params, this.blockHasShapeDivider(block), true)
  }

  setValue(block) {
    let params = {
      shape: this.controls.list.value,
      width: this.controls.width.value,
      height: this.controls.height.value,
      fliped: !this.controls.flip_no.checked,
      position: this.controls.position_top.checked,
      color: this.controls.frame_color.value || "#000",
    }

    this.updateShapeDivider(block, params, this.blockHasShapeDivider(block))
  }

  blockHasShapeDivider(block) {
    return !!block.querySelector('.custom-shape-divider')
  }

  updateShapeDivider(block, params, blockHasShape, setup = false) {
    if (blockHasShape) {
      let shapeDivider = block.querySelector('.custom-shape-divider')
      shapeDivider.parentNode.removeChild(shapeDivider)
    }

    if (setup) {
      this.controls.list.value = params.shape
      this.controls.width.value = params.width
      this.controls.height.value = params.height
      if (!params.fliped) this.controls.flip_no.checked = true
      if (!params.position) this.controls.position_bottom.checked = true
      this.controls.frame_color.value = params.color
    }

    let frame = this.getFrame(this.controls.list.value)
    let customShapeDivider = document.createElement('div')
    customShapeDivider.setAttribute('class', 'custom-shape-divider')
    customShapeDivider.appendChild(frame)
    let svg = customShapeDivider.querySelector('svg')
    block.insertBefore(customShapeDivider, block.firstChild)

    block.style.position = 'relative'

    svg.style.width = `calc(${params.width}% + 0.08125rem)`
    svg.style.height = `${params.height}px`
    Array.from(svg.querySelectorAll('path')).forEach(path => {
      path.style.fill = params.color
    })

    svg.style.transform = params.fliped ? 'rotateY(180deg)' : 'none'
    params.position ? customShapeDivider.style.top = '0' : customShapeDivider.style.bottom = '0'
    if (params.position) {
      customShapeDivider.style.top = '0'
      customShapeDivider.style.transform = 'none'
    } else {
      customShapeDivider.style.transform = 'rotate(180deg)'
      customShapeDivider.style.bottom = '0'
    }

    this.setBlockShapeParams(block, params)
  }

  setBlockShapeParams(block, params) {
    let shapeData = `${params.shape},${params.width},${params.height},${params.fliped ? '1':'0'},${params.position ? '1':'0'},${params.color}`
    block.setAttribute('data-shape-params', shapeData)
  }

  getFrame(name) {
    console.log(name)
    let frame = document.querySelector(`#section-frame-${name}`)
    return new DOMParser().parseFromString(frame.innerHTML, "text/xml").childNodes[0]
  }
}