import Control from './control.js'

export default class LinkControl extends Control {

    setup() {
        this.img = this.block
        this.urlInput = this.matchable.querySelector('#block-link')
        if (this.img.hasAttribute('data-href'))
            this.urlInput.value = this.img.dataset.href
        else
            this.urlInput.value = ''
    }

    setValue(e) {
        const url = this.urlInput.value
        if (window.Helpers.isValidHttpUrl(url) || url.startsWith('/')) {
            this.img.dataset.href = url
            showFlash(I18n.editor.url_redirection_success, false)
        } else {
            showFlash(I18n.editor.src_error, true)
        }
    }
}