import Control from './control.js'

const ALIGNMENTS_CLASSES = {
  space_around: 'justify-content-around',
  space_between: 'justify-content-between',
  center: 'justify-content-center',
  right: 'justify-content-end'
}

export default class AlignmentControl extends Control {
  setup () {
    const flexBlock = this.block.parentElement
    this.control.selectedIndex = 0
    for (const alignment in ALIGNMENTS_CLASSES) {
      const klass = ALIGNMENTS_CLASSES[alignment]
      if (!flexBlock.classList.contains(klass)) continue
      window.Helpers.setSelectedValue(this.control, alignment)
    }
  }

  setValue (e) {
    const flexBlock = this.block.parentElement
    const klasses = Object.values(ALIGNMENTS_CLASSES)
    for (const klass of klasses) flexBlock.classList.remove(klass)
    const alignment = this.control.value
    if (alignment === 'left') return
    flexBlock.classList.add(ALIGNMENTS_CLASSES[alignment])
    this.changeCb()
  }
}