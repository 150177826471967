function importAll(object, r) {
  r.keys().forEach((key) => {
    const parts = key.split('_')
    parts.pop()
    let id = parts.join('_')
    id = id ? id.substring(2) : 'generic' 
    object[id] = r(key).default
  })
}

const BRIDGES = {}
const CONTROLS = {}

importAll(BRIDGES, require.context('./bridges', true, /bridge.js$/))
importAll(CONTROLS, require.context('./controls', true, /control.js$/))


export function attachControls (canvas, editor, form, inspectorElement) {
  const controls = new Array()
  const bridges = {}

  inspectorElement.querySelectorAll('[data-control]').forEach((element) => {
    const ControlClass = CONTROLS[element.dataset.control]
    const bridgeId = ControlClass.bridgeId || element.dataset.controlBridge

    let bridge = bridgeId ? bridges[bridgeId] : undefined
    if (bridgeId && !bridge) {
      bridge = new (BRIDGES[bridgeId] || BRIDGES.generic)(bridgeId, canvas, editor, form)
      bridges[bridgeId] = bridge
    }

    const control = new ControlClass(element, bridge)
    controls.push(control)
  })

  return controls
}