document.addEventListener("turbolinks:load", function() {
  document.querySelectorAll('.hide-trigger').forEach((trigger) => {
    const targets = document.querySelectorAll(`[data-hide-trigger="#${trigger.id}"]`)
    const toggleVisibility = () => {
      targets.forEach((target) => {
        let value = target.dataset.hideFor
        if (trigger.checked !== undefined) value = toBoolean(value)
        const hide = window.Helpers.getControlValue(trigger) === value
        if (target.dataset.hideDisable) {
          target.disable = hide
          return
        } 
        target.classList.toggle('d-none', hide)
      })
    }
    trigger.addEventListener('change', toggleVisibility)
    trigger.addEventListener('helper:change', toggleVisibility)
    toggleVisibility()
  })
})

function toBoolean (value) {
  return value === 'true'
}