import Control from './control.js'
import Rails from '@rails/ujs'

export default class ProductControl extends Control {

  init () {
    this.inputs = document.querySelectorAll('#new-product input')
    this.orderProductName = document.querySelector('#block-order-product-name')
    this.newProductCollapse = this.control.closest('.collapse')
  }

  resetInputs () {
    this.inputs.forEach((input) => input.value = '')
    this.control.removeAttribute('disabled')
  }

  productData () {
    const formData = new FormData()
    formData.append('hidden', 1)
    this.inputs.forEach((input) => formData.append(input.dataset.name, input.value))
    return formData
  }

  setup () {
    this.resetInputs()
  }

  setValue (e) {
    for (const input of this.inputs) {
      if (!input.checkValidity() || !input.value) {
        input.required = true
        input.reportValidity()
        input.removeAttribute('required')
        return
      }
    }

    const afterAjaxCompleted = () => { this.control.removeAttribute('disabled') }
    this.control.disabled = true

    Rails.ajax({
      type: 'POST',
      url: '/admin/products',
      data: this.productData(),
      success: (response) => {
        const product = response.product
        this.bridge.products[product.hashid] = product
        this.orderProductName.innerHTML = product.name
        this.block.dataset.orderProduct = product.hashid
        this.resetInputs()
        afterAjaxCompleted()
        $(this.newProductCollapse).collapse('hide')
        window.showFlash(response.notice, false)
        this.changeCb()
      },
      error: afterAjaxCompleted
    })

  }
}