import Control from './control.js'

export default class GradientControl extends Control {
  static bridgeId = 'gradient'

  setup () {
    if (this.attribute === 'firstColor') {
      this.bridge.setup(this.block)
    }
  }

  setValue (e) {
    e.preventDefault()
    if (this.attribute == 'removeGradient') this.block.style.backgroundImage = ''
    else this.bridge.setValue(this.block, this.attribute)
    this.changeCb()
    this.callAction('fillBlock')
  }
}