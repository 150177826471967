document.addEventListener("turbolinks:load", function() {
  const domainElement = document.querySelector('#account_domain')

  if (domainElement) {
    const subdomainElement = document.querySelector('#account_subdomain')
    const originalSubdomain = subdomainElement.value

    $(document).on("shown.bs.collapse", "#account-domain", function () {
      subdomainElement.value = originalSubdomain
    })

    $(document).on("shown.bs.collapse", "#account-subdomain", function () {
      domainElement.value = ''
    })
  }
})
