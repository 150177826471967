import Bridge from './bridge.js'

export default class FrameBridge extends Bridge {

    onActionsSet() {
        this.callAction('registerBeforeSubmitHook', { func: this.emptyFrameContent.bind(this) })
    }

    emptyFrameContent () {
        const iframes = this.canvas.querySelectorAll('[frame-container] iframe')
        iframes.forEach(iframe => {
            iframe.innerHTML = ''
        });
    }
}