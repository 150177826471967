export default class Bridge {
  _super(that, method, args) {
    Bridge.prototype[method].apply(that, args || [])
  }

  constructor (id, canvas, editor, form) {
    this.id = id
    this.canvas = canvas
    this.editor = editor
    this.form = form
    this._actions = {}
    this.controls = {}
    this.disposed = false

    this.products = {}
    this.init()
  }

  init () {}

  get actions () {
    return this._actions
  }

  set actions (actions) {
    // Can only be set once!
    if (Object.keys(this._actions).length != 0) return
    this._actions = actions
    this.onActionsSet()
  }

  onActionsSet () { }

  dispose () {
    if (this.disposed) return false
    this.disposed = true
    this.deinit()
    return true
  }

  deinit () {

  }

  callAction (name, params) {
    if (typeof this._actions[name] === 'function') this._actions[name](params)
  }
}
