import StyleControl from './style_control.js'

export default class ColorControl extends StyleControl {
  setup () {
    this._super(this, 'setup')
    this.control.dispatchEvent(new Event('pickr-change'))
  }

  get event () {
    return 'change'
  }
}