import 'daterangepicker-dayjs/daterangepicker'
import dayjs from 'dayjs'

import localeData from 'dayjs/plugin/localeData'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import isoWeek from 'dayjs/plugin/isoWeek'
import arraySupport from 'dayjs/plugin/arraySupport'
import badMutable from 'dayjs/plugin/badMutable'

// Load plugins
dayjs.extend(localeData)
dayjs.extend(localizedFormat)
dayjs.extend(isoWeek)
dayjs.extend(arraySupport)
dayjs.extend(badMutable)

import 'dayjs/locale/en' // load on demand
import 'dayjs/locale/fr' // load on demand

let lang = document.documentElement.lang
lang = new Array('en', 'fr').includes(lang) ? lang : 'en'

dayjs.locale(lang)

const injectDaterangepicker = function (element) {
  if (!element) {
    return
  }

  $(element).daterangepicker(
    {
      endDate: dayjs(element.dataset.endDate),
      startDate: dayjs(element.dataset.startDate),
      minDate: dayjs(element.dataset.minDate),
      maxDate: dayjs(),
      opens: 'left',
      showDropdowns: true,
      ranges: {
        [I18n.daterange.today]: [dayjs(), dayjs()],
        [I18n.daterange.yesterday]: [dayjs().subtract(1, 'days'), dayjs().subtract(1, 'days')],
        [I18n.daterange.last_seven_days]: [dayjs().subtract(6, 'days'), dayjs()],
        [I18n.daterange.last_thirty_days]: [dayjs().subtract(29, 'days'), dayjs()],
        [I18n.daterange.this_month]: [dayjs().startOf('month'), dayjs().endOf('month')],
        [I18n.daterange.all_time]: [dayjs(element.dataset.minDate), dayjs()]
      },
      locale: {
        format: I18n.daterange.date_format,
        separator: ' - ',
        applyLabel: I18n.ok,
        cancelLabel: I18n.cancel,
        fromLabel: I18n.from,
        toLabel: I18n.to,
        customRangeLabel: I18n.daterange.custom
      }
    },
    function (start, end) {
      const firstChar = window.location.search.indexOf('?') > -1 ? '&' : '?'

      window.location.search = `${firstChar}start_date=${start.format(
        'YYYY-MM-DD'
      )}&end_date=${end.format('YYYY-MM-DD')}`
    }
  )
}

const injectDatetimepicker = function (element, elementIso) {
  if (!element) {
    return
  }
  const tomorrow = new Date()
  tomorrow.setDate(new Date().getDate() + 1)
  const startDate = element.value.length > 0 ? new Date(Date.parse(element.value)) : tomorrow
  if (elementIso) {
    elementIso.value = startDate.toISOString()
  }

  $(element).daterangepicker(
    {
      timePicker: true,
      timePicker24Hour: true,
      minYear: 2020,
      minDate: new Date(),
      startDate: startDate,
      singleDatePicker: true,
      showDropdowns: true,
      drops: 'auto',
      opens: 'right',
      locale: {
        format: I18n.daterange.time_format,
        applyLabel: I18n.ok,
        cancelLabel: I18n.cancel
      }
    },
    function (datetime) {
      if (elementIso) {
        elementIso.value = datetime.toISOString()
      }
    }
  )
}

document.addEventListener('turbolinks:load', function () {
  injectDaterangepicker(document.querySelector('.daterange'))

  const inputs = document.querySelectorAll('input.datetime')

  if (inputs) {
    inputs.forEach((input) => {
      injectDatetimepicker(input, document.querySelector(input.dataset.iso))
    })
  }
})
