import Control from './control.js'

export default class ShadowControl extends Control {
  static bridgeId = 'shadow'

  setup () {
    if (this.attribute === 'shadowColor') {
      this.bridge.setup(this.block)
    }
  }

  setValue (e) {
    this.bridge.setValue(this.block)
    this.changeCb()
  }
}