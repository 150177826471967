import { Controller } from "stimulus"

export default class extends Controller {
  connect () {
    this.isActive = false
    this.beforeUnloadLtn = (e) => {
      if (this.isActive) {
        e.preventDefault()
        e.returnValue = ''
      }
    }
    this.beforeVisitLtn = (e) => {
      if (this.isActive && !confirm(I18n.unload_blocker.warning)) {
        e.preventDefault()
      }
    }

    this.ajaxSuccessLtn = (e) => this.deactivate()
    this.element.addEventListener('ajax:success', this.ajaxSuccessLtn, false)

    window.addEventListener('beforeunload', this.beforeUnloadLtn, false)
    document.addEventListener('turbolinks:before-visit', this.beforeVisitLtn, false)
  }

  disconnect () {
    this.element.removeEventListener('ajax:success', this.ajaxSuccessLtn, false)
    window.removeEventListener('beforeunload', this.beforeUnloadLtn, false)
    document.removeEventListener('turbolinks:before-visit', this.beforeVisitLtn, false)
  }

  activate () {
    this.isActive = true
  }

  deactivate () {
    this.isActive = false
  }
}
