import Control from './control.js'
const placeholderImageRegex = /\/placeholders\//

export default class SrcControl extends Control {
  static bridgeId = 'image'

  init () {
    this.controlLabel = this.control.parentElement.querySelector(`label[for="${this.control.id}"]`)
  }

  setup () {
    if (this.block.tagName === 'IMG') {
      this.image = this.block
      return
    }
    this.image = this.block.firstElementChild
    this.setLabel()
  }

  setValue (e) {
    this.bridge.uploadImage(this.control, (path, objectUrl) => {
      this.image.dataset.src = path
      this.image.setAttribute('src', objectUrl)
      this.constrainImage()
      this.setLabel()
      this.changeCb()
    })
  }

  setLabel () {
    const src = this.image.dataset.src || this.image.src
    if (!this.image || src == undefined) return
    if (placeholderImageRegex.test(src) || src === "") {
      this.controlLabel.innerHTML = I18n.editor.image_change_label
    } else {
      this.controlLabel.innerHTML = src.split('/').pop()
    }
  }

  get event () {
    return 'change'
  }

  get imageConstrained () {
    return !!this.control.dataset.imageConstrained
  }

  constrainImage () {
    if (!this.imageConstrained) return
    const setImageWidthLtn = (e) => {
      if (e.target.naturalWidth > 600) e.target.width = e.target.width || 600
      e.target.removeEventListener('load', setImageWidthLtn, false)
    }
    this.image.addEventListener('load', setImageWidthLtn, false);
  }
}