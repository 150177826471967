import Bridge from './bridge.js'

const attributes = new Array('firstColor', 'firstColorStop', 'secondColor', 'secondColorStop')

export default class ShadowBridge extends Bridge {
  setup (block) {
    const bgImage = block.style.backgroundImage

    if (!bgImage || bgImage.includes('url')) {
      this.controls.firstColorStop.value = '0'
      this.controls.secondColorStop.value = '100'
      new Array('firstColor', 'secondColor').forEach((attribute) => {
        this.controls[attribute].value = '#FFF'
        this.controls[attribute].dispatchEvent(new Event('pickr-change'))
      })

      const directionRightBtn = this.controls.directionRight.parentElement

      $(directionRightBtn).button('toggle')
      directionRightBtn.classList.remove('focus')
      directionRightBtn.classList.remove('active')
    } else {
      attributes.filter(attribute => !attribute.endsWith('Stop'))
        .forEach((attribute, index) => {
          const value = this.gradientColors(block).split(',')[index]
          this.controls[attribute].value = value
          this.controls[attribute].dispatchEvent(new Event('pickr-change'))
        })
      attributes.filter(attribute => attribute.endsWith('Stop'))
        .forEach((attribute, index) => {
          const value = this.gradientColorStops(block).split(',')[index]
          this.controls[attribute].value = value
        })
      const directionString = block.dataset.gradientDirection || 'Right'
      const directionControl = this.controls[`direction${directionString.replace(' ', '')}`]
      $(directionControl.parentElement).button('toggle')
    }
  }

  gradientColors(block) {
    return block.dataset.gradientColors || '#FFF,#FFF'
  }

  gradientColorStops(block) {
    return block.dataset.gradientColorStops || '0,100'
  }

  setValue (block, attribute) {
    if (attribute.startsWith('direction')) this.currentDirection = this.controls[attribute].value
    const linearGradientValues = attributes.map((attribute) => this.controls[attribute].value)
    linearGradientValues.unshift(this.currentDirection || 'Right')
    const linearGradientStringValue = `to ${linearGradientValues[0].toLowerCase()}, ${linearGradientValues[1]} ${linearGradientValues[2]}%, ${linearGradientValues[3]} ${linearGradientValues[4]}%`
    block.dataset.gradientColors = [linearGradientValues[1], linearGradientValues[3]]
    block.dataset.gradientColorStops = [linearGradientValues[2], linearGradientValues[4]]
    block.dataset.gradientDirection = linearGradientValues[0]
    block.style.backgroundImage = `linear-gradient(${linearGradientStringValue})`   
  }
}