import Control from './control.js'

export default class JsonControl extends Control {
  setup () {
    const jsonString = this.block.getAttribute(this.attribute)
    if (!jsonString) return
    const configs = JSON.parse(jsonString)
    this.value = configs[this.jsonKey]
  }

  get jsonKey () {
    return this.control.dataset.controlKey
  }

  setValue (e) {
    let json = {}
    const jsonString = this.block.getAttribute(this.attribute)
    if (jsonString) json = JSON.parse(jsonString)
    json[this.jsonKey] = this.value
    this.block.setAttribute(this.attribute, JSON.stringify(json))
    this.changeCb()
  }
}