import Control from './control.js'
import { BlockManager } from '../../page_editor/block_manager.js'

export default class SubmenuControl extends Control {
  setup () {
    const canAddSubmenu = this.block.matches('.nav-link:not(.dropdown-toggle):not(.dropdown-item)')
    this.control.parentElement.style.display = canAddSubmenu ? 'block' : 'none'
  }

  setValue (e) {
    const navbarTemplate = BlockManager.getBlock('navbar')
    if (!navbarTemplate) return

    const dropdownMenu = navbarTemplate.querySelector('.site-dropdown-menu')

    this.block.classList.add('dropdown-toggle')
    this.block.classList.add('site-dropdown-toggle')
    this.block.setAttribute('id', `dropdown-${window.Helpers.currentTime36()}`)
    dropdownMenu.setAttribute('aria-labelledby', this.block.id)
    let i = 1
    dropdownMenu.querySelectorAll('.dropdown-item').forEach((dropdownItem) => {
      if (i <= 3) {
        dropdownItem.href = ''
        dropdownItem.innerText = `Item ${i}`
        i++
        return
      }

      dropdownItem.remove()
    })
    this.block.parentElement.insertBefore(dropdownMenu, this.block.nextElementSibling)
    this.block.parentElement.classList.add('dropdown')
    this.control.parentElement.style.display = 'none'
    this.changeCb()
  }
}