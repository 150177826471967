document.addEventListener('turbolinks:load', function() {
  const elements = document.querySelectorAll('[data-bind]')

  if (!elements) {
    return
  }

  elements.forEach(function(element) {
    const input = document.querySelector(element.dataset.bind)
    const defaultValue = element.innerHTML || input.defaultValue
    element.innerHTML = input.value || defaultValue
    input.addEventListener('input', ()=>{
      element.innerHTML = input.value || defaultValue
    })
  })
})
