import Control from './control.js'
import { BlockManager } from '../../page_editor/block_manager.js'

export default class CustomBlockControl extends Control {
  static bridgeId = 'custom_block'

  setup () {
    if (this.attribute === 'blockReusable') {
      this.bridge.selection = this.selection

      this.bridge.originalBlock = BlockManager.getBlock(this.bridge.blockId)
      this.bridge.setupCustomBlock()
    }
  }

  setValue (e) {
    if (this.attribute === 'blockReusable' && !this.control.checked && this.block.dataset.configBlockId) {
      if (confirm(I18n.editor.confirm_reusable_block_unsync)) {
        this.bridge.setupRegularBlock()
        this.callAction('fillBlock')
        this.changeCb()
      } else {
        this.value = true
        e.preventDefault()
        return false
      }
    } else if (this.attribute === 'blockCreate') {
      this.bridge.createBlock()
    }
  }
}