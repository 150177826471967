import Bridge from './bridge.js'

export default class ShadowBridge extends Bridge {
  setup (block) {
    const suffixes = new Array("spreadRadius", "blurRadius", "offsetY", "offsetX")

    if (block.style.boxShadow !== "") {
      const shadowValues = block.style.boxShadow.split(" ").reverse()
      suffixes.forEach((suffix, index) => {
        this.controls[suffix].value = window.Helpers.extractPixelValue(shadowValues[index])
      })
      this.controls.shadowColor.value = block.dataset.boxShadowColor
    } else {
      suffixes.forEach((suffix) => {
        this.controls[suffix].value = "0"
      })
      this.controls.shadowColor.value = "#000"
    }
    this.controls.shadowColor.dispatchEvent(new Event('pickr-change'))
  }

  setValue (block) {
    const attributes = new Array('offsetX', 'offsetY', 'blurRadius', 'spreadRadius', 'shadowColor')
    const boxShadowValues = attributes.map((attribute, index) => {
      const value = this.controls[attribute].value
      return (index === 4) ? (value || "#000") : value + 'px'
    })

    block.dataset.boxShadowColor = boxShadowValues[4]
    block.style.boxShadow = boxShadowValues.join(" ")
  }
}