import introJs from "intro.js";

document.addEventListener("turbolinks:load", function() {
  var arr = window.location.pathname.split("/");
  if(!checkCookie() && (arr[1] == "admin" && arr[2] == "pages" && arr[4] == "edit"))
  {
    var my_intro = introJs()
    my_intro.setOptions({
      steps: [{
        title: I18n.onboarding_tour.step1_title,
        intro: I18n.onboarding_tour.step1_intro
      },
      {
        element: document.querySelector('.page-form'),
        title: I18n.onboarding_tour.step2_title,
        intro: I18n.onboarding_tour.step2_intro
      },
      {
        element: document.querySelector('.custom-select'),
        title: I18n.onboarding_tour.step3_title,
        intro: I18n.onboarding_tour.step3_intro
      },
      {
        element: document.querySelector('.site > .navbar'),
        title: I18n.onboarding_tour.step4_title,
        intro: I18n.onboarding_tour.step4_intro
      },
      {
        element: document.querySelector('#save_page'),
        title: I18n.onboarding_tour.step5_title,
        intro: I18n.onboarding_tour.step5_intro
      },
      {
        element: document.querySelector('#crisp-chatbox > .cc-unoo'),
        title: I18n.onboarding_tour.step6_title,
        intro: I18n.onboarding_tour.step6_intro
      }]
    }).oncomplete(function() {
      setCookie("onboarding_tour", true, 365);
    }).onexit(function() {
      setCookie("onboarding_tour", true, 365);
    }).start();
  }

  function getCookie(cname)
  {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    var expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  function checkCookie()
  {
    var onboarding_tour = getCookie("onboarding_tour");
    return onboarding_tour != ""
  }

});
