import Rails from '@rails/ujs'

document.addEventListener('turbolinks:load', function () {
  if (window.location.pathname.startsWith('/admin/articles')) {
    const form = document.querySelector('form')
    let draft = false

    if (!form) return

    const submitInputs = form.querySelectorAll('input[type="submit"]')
    submitInputs.forEach((input) => {
      input.addEventListener('click', (e) => {
        submitInputs.forEach((i) => {
          i.removeAttribute('clicked')
        })

        input.setAttribute('clicked', true)
        draft = (e.target.name === 'draft')
      })
    })

    form.addEventListener('submit', function (e) {
      e.preventDefault()

      submitInputs.forEach((input) => {
        input.setAttribute('disabled', true)
      })

      form.removeAttribute('data-remote')

      const contentInput = form.querySelector('textarea.summernote')

      const html = contentInput.value

      const activeStorageBlobExtractor = /\/active_storage\/(?:blobs|representations)\/(?:redirect\/){0,1}(\w|\-)*/g

      Array.from(html.match(activeStorageBlobExtractor) || []).forEach((match) => {
        const input = document.createElement('input')
        input.type = 'hidden'
        input.name = 'article[images][]'
        const parts = match.split('/')
        input.value = parts[parts.length - 1]
        form.appendChild(input)        
      })

      const enableSubmitButtons = (_) => {
        submitInputs.forEach((input) => {
          input.removeAttribute('disabled')
        })
      }

      const formData = new FormData(form)
      if (draft) formData.set('draft', 1)

      Rails.ajax({
        type: "POST",
        url: form.action,
        data: formData,
        success: enableSubmitButtons,
        error: enableSubmitButtons
      })
    })
  }
})
