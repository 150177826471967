export default class Control {
  _super(that, method, args) {
    Control.prototype[method].apply(that, args || [])
  }

  constructor (control, bridge) {
    this.control = control
    this.bridge = bridge
    this.actions = {}
    this.matchable = this.control.closest('[data-match]')
    if (bridge) {
      this.bridge.controls[this.attribute] = this.control
      this.bridge.changeCb = (data) => this.changeCb(data)
    }
    const errorSelector = this.control.dataset.controlError
    if (errorSelector) this.errorElement = document.querySelector(errorSelector)
    this.init()
    this.listen()
  }

  init () { }

  changeCb (data) {
    if (this.selection) {
      const event = new Event('block:changed', { bubbles: true })
      event.data = data || {}
      this.block.dispatchEvent(event)
    }
  }

  set actions (actions) {
    this._actions = actions
    if (this.bridge) this.bridge.actions = actions
  }

  get actions () {
    return this._actions
  }

  callAction (name, params) {
    if (typeof this.actions[name] === 'function') this.actions[name](params)
  }

  listen () {
    this.setValueLtn = (e) => {
      if (this.validate()) {
        this.setValue(e)
      } else {
        this.onError()
      }
    }
    this.control.addEventListener(this.event, this.setValueLtn)
  }

  dispose () {
    this.control.removeEventListener(this.event, this.setValueLtn)
    if (this.bridge) this.bridge.dispose()
  }

  set selection (selection) {
    this._selection = selection
    if (this.matchable && this.matchable.style.display === 'none') return
    this.setup()
  }

  get selection () {
    return this._selection
  }

  get block () {
    return this.selection.block
  }

  get event () {
    return this.control.dataset.controlEvent || (
      (this.control.checked !== undefined) ? 'change' : 'input'
    )
  }

  get initEvent () {
    return this.control.dataset.controlInitEvent
  }

  get suffix () {
    return this.control.dataset.controlSuffix
  }

  get value () {
    const value = window.Helpers.getControlValue(this.control)

    if (this.suffix && value) {
      return value + this.suffix
    }

    return value
  }

  set value (data) {
    if (data && this.suffix) data = data.substring(0, data.length - this.suffix.length)
    window.Helpers.setControlValue(this.control, data)
  }

  get attribute () {
    return this.control.dataset.controlAttribute
  }

  get pattern () {
    return this.control.dataset.controlPattern
  }

  get alert () {
    return this.control.dataset.controlAlert
  }

  onError () {
    if (this.alert) {
      showFlash(this.alert, true)
      this.control.value = ''
      this.block.removeAttribute(this.attribute)
    } else if (this.errorElement) {
      this.errorElement.style.display = 'block'
      this.block.removeAttribute(this.attribute)
    } else {
      this.setup()
    }
  }

  validate () {
    if (this.errorElement) this.errorElement.style.display = 'none'
    if (this.control.tagName !== 'INPUT' && this.control.tagName !== 'TEXTAREA') {
      return true
    } else if (this.control.type === 'number') {
      const value = parseFloat(this.control)
      if (value === NaN) return false
      if (this.control.min && value < parseFloat(this.control.min)) return false
      if (this.control.max && value > parseFloat(this.control.max)) return false
      if (this.control.step && Helpers.isInt(this.control.step) && !Helpers.isInt(this.control.value)) return false
      return true
    } else if (this.pattern) {
      return new RegExp(this.pattern).test(this.control.value)
    } else {
      return true
    }
  }

  setup () {
    if (this.initEvent) {
      const event = new Event(this.initEvent)
      event.data = this.block.getAttribute(this.attribute)
      this.control.dispatchEvent(event)
    } else {
      this.value = this.block.getAttribute(this.attribute)
    }
  }

  setValue (e) {
    const block = this.block
    if (this.event.includes(':') && e.data == "") {
      block.removeAttribute(this.attribute)
    } else if (this.event.includes(':') && e.data) {
      block.setAttribute(this.attribute, e.data)
    } else if (this.control.dataset.controlToggle && this.value) {
      block.setAttribute(this.attribute, this.control.dataset.controlToggle)
      this.callAction('reset')
    } else if (this.control.dataset.controlToggle && !this.value) {
      this.block.removeAttribute(this.attribute)
    } else {
      block.setAttribute(this.attribute, this.value)
    }
    this.changeCb()
  }
}