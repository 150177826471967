import Rails from '@rails/ujs'

document.addEventListener('turbolinks:load', function() {
  const translationsForm = document.querySelector('#translations-form')
  if (!translationsForm) return

  const submitButton = translationsForm.querySelector('#translations-form-submit')
  const translationTableBody = document.querySelector('#translations-table-body')

  const transatableTypeInput =  translationsForm.querySelector('#translation-translatable-type')
  const accountIdSelect = translationsForm.querySelector('#translation-account-id')

  submitButton.addEventListener('click', ()=>{

    if (
      accountIdSelect.selectedOptions.length === 0  ||
      accountIdSelect.selectedOptions[0].value === ""
    ) return

    submitButton.disabled = true
    const formData = new FormData()

    formData.append('translation[translatable_type]', transatableTypeInput.value)
    formData.append('translation[account_id]', accountIdSelect.selectedOptions[0].value)

    Rails.ajax({
      type: "POST",
      url: translationsForm.dataset.url,
      data: formData,
      success: (response) => {
        translationTableBody.insertAdjacentHTML("beforeend", response.html)
        const translation = translationTableBody.lastElementChild
        const accountId = translation.dataset.accountid
        const option = accountIdSelect.querySelector(`option[value="${accountId}"]`)
        if (option) option.remove()

        submitButton.disabled = false
        showFlash(I18n.translations.create_success, false)
      },
      error: (_) => {
        showFlash(I18n.translations.create_error, true)
        submitButton.disabled = false
      }
    })
  })
})
