document.addEventListener("turbolinks:load", function() {
  window.addEventListener('click', disableNavigation)
  window.addEventListener('invalid', disableNavigation)
  window.addEventListener('submit', disableNavigation)

  function disableNavigation (e) {
    if (e.target.closest('[data-disable-navigation]')) {
      e.preventDefault()
    }
  }
})
