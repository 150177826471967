import Control from './control.js'

export default class BackgroundImageControl extends Control {
  static bridgeId = 'image'

  init () {
    if (this.attribute === 'backgroundImage') {
      this.controlLabel = this.control.parentElement.querySelector(`label[for="${this.control.id}"]`)
      this.bridge.bgControlLabel = this.controlLabel
    }
  }

  removeBackgroundImage (e) {
    e.preventDefault()
    this.block.style.backgroundImage = ''
    if (this.block.classList.contains('header')) this.block.classList.add('opacity-0')
    this.setup()
  }

  setup () {
    if (this.attribute === 'backgroundImage') {
      this.bridge.setupBackgroundImage(this.block)
    } else {
      this.control.style.display =  !!this.block.style.backgroundImage ? 'block' : 'none'
    }
  }

  setValue (e) {
    if (this.attribute === 'backgroundImage') {
      this.bridge.uploadImage(this.control, (path) => {
        if (this.block.classList.contains('header')) this.block.classList.remove('opacity-0')
        this.block.style.backgroundImage = `url("${window.encodeURI(path)}`
      })
    } else {
      this.removeBackgroundImage(e)
    }
    this.bridge.setupBackgroundImage(this.block)
    this.changeCb()
  }

  get event () {
    return this.control.dataset.controlEvent || 'change'
  }
}