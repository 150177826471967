import Control from './control.js'
import dayjs from 'dayjs'

export default class CountdownControl extends Control {
  get event() {
    return 'apply.daterangepicker'
  }

  listen() {
    this.setValueLtn = (e, picker) => this.setValue(picker)
    $(this.control).on(this.event, this.setValueLtn)
  }

  dispose() {
    $(this.control).off(this.event, this.setValueLtn)
  }

  setup() {
    if (this.block.dataset.countdown) {
      $(this.control).data('daterangepicker').setStartDate(dayjs(this.block.dataset.countdown))
    } else {
      $(this.control).data('daterangepicker').setStartDate(new Date())
    }
  }

  setValue(picker) {
    if (!this.control.value) {
      return
    }
    this.block.dataset.countdown = picker.startDate.toISOString()
    this.changeCb()
  }
}
