export class BlockManager {
  constructor (canvas) {
    this.canvas = canvas
  }

  static getBlock (name) {
    const block = document.querySelector(`#block-template-${name}`)
    if (!block) return null
    const container = document.createElement('div')
    container.innerHTML = block.innerHTML
    const node = container.firstElementChild
    if (block.dataset.configBlockTime) {
      node.dataset.configBlockId = name
      node.dataset.blockName = block.dataset.blockName
      node.dataset.configBlockTime = block.dataset.configBlockTime
    }
    return node
  }

  static setBlock (name, displayName, node) {
    const activeElement = document.querySelector(`#page-editor-blocks [data-name="${name}"]`)
    if (activeElement) {
      activeElement.closest('.page-editor-block-button').querySelectorAll('.block-name').forEach((element)=>{
        element.textContent = displayName
      })
    }

    const block = document.querySelector(`#block-template-${name}`)
    if (block) {
      block.dataset.configBlockTime = node.dataset.configBlockTime
      block.dataset.blockName = displayName
      block.innerHTML = node.outerHTML
    }
  }

  static createBlock(name, node, editor) {
    const displayName = node.dataset.blockName
    const customBlockButtonTemplate = document.querySelector(`#custom-block-button-template`)
    const container = document.createElement('div')
    container.innerHTML = customBlockButtonTemplate.innerHTML
    const customBlockButton = container.firstElementChild
    customBlockButton.querySelectorAll('.block-name').forEach((element)=>{
      element.textContent = displayName
    })

    customBlockButton.querySelectorAll('[data-action]').forEach((element)=>{
      element.dataset.name = name
      element.dataset.id = name
    })

    document.querySelector('#page-editor-blocks').appendChild(customBlockButton)

    const block = document.createElement('template')
    block.id = `block-template-${name}`
    block.dataset.configBlockTime = node.dataset.configBlockTime
    block.dataset.blockName = displayName
    block.innerHTML = node.outerHTML
    editor.appendChild(block)
  }

  static deleteBlock (name) {
    const block = document.querySelector(`#block-template-${name}`)
    if (block) block.remove()
    const activeElement = document.querySelector(`#page-editor-blocks [data-name="${name}"]`)
    if (activeElement) activeElement.closest('.page-editor-block-button').remove()
  }

  append (name) {
    const node = BlockManager.getBlock(name)
    this.disambiguateId(node, { deep: true })
    this.canvas.appendChild(node)

    const element = this.canvas.lastElementChild
    element.classList.add('site-block')
    element.scrollIntoView()
    element.dispatchEvent(new Event('block:added', { bubbles: true }))

    return element
  }

  canMove (node, direction) {
    const target = this.actionTarget(node)

    if (direction === 'up') {
      return !!this.previousElementForMove(target)
    } else if (direction === 'down') {
      return !!this.nextElementForMove(target)
    }
  }

  moveUp (node) {
    const target = this.actionTarget(node)
    const previousElement = this.previousElementForMove(target)

    if (previousElement) {
      target.parentNode.insertBefore(target, previousElement)
      target.scrollIntoView()
      target.dispatchEvent(new Event('block:moved', { bubbles: true }))
    }
  }

  moveDown (node) {
    const target = this.actionTarget(node)
    const nextElement = this.nextElementForMove(target)

    if (nextElement) {
      target.parentNode.insertBefore(target, nextElement.nextElementSibling)
      target.scrollIntoView(false)
      target.dispatchEvent(new Event('block:moved', { bubbles: true }))
    }
  }

  actionTarget (node) {
    let target = node

    if (node.parentNode && this.visibleChildren(node.parentNode).length === 1 && node.parentNode !== this.canvas) {
      target = this.actionTarget(node.parentNode)
    }

    return target
  }

  visibleChildren (node) {
    return Array.from(node.children).filter((child) => {
      return !!child.offsetParent && !child.classList.contains('sr-only')
    })
  }

  previousElementForMove (target) {
    if (target.parentNode !== this.canvas && target.parentNode.childElementCount === 1) {
      return this.previousElementForMove(target.parentNode)
    }

    return target.previousElementSibling
  }

  nextElementForMove (target) {
    return target.nextElementSibling
  }

  copy (node) {
    const target = this.actionTarget(node)
    const clone = target.cloneNode(true)

    this.disambiguateId(clone, { deep: true })

    target.parentNode.insertBefore(clone, target.nextElementSibling)
    clone.scrollIntoView()
    const event = new Event('block:cloned', { bubbles: true })
    event.clone = clone
    target.dispatchEvent(event)

    return clone
  }

  delete (node) {
    const parent = node.parentNode

    parent.removeChild(node)

    if (parent.children.length === 0 && parent !== this.canvas) {
      this.delete(parent)
    } else if (parent.classList.contains('dropdown') && parent.firstElementChild && parent.firstElementChild.classList.contains('site-dropdown-menu')) {
      this.delete(parent)
    } else {
      const event = new Event('block:removed', { bubbles: true })
      event.data = { block: node }
      parent.dispatchEvent(event)
    }
  }

  disambiguateId (target, options) {
    const id = target.id
    if (id && this.canvas.querySelector(`#${id}`)) {
      const newId = `${id}-${window.Helpers.currentTime36()}`
      target.id = newId

      const newIdSelector = `#${newId}`
      const block = options.block || target

      Array.from(['href', 'data-parent', 'data-target']).forEach((attribute) => {
        block.querySelectorAll(`[${attribute}="#${id}"]`).forEach((element) => {
          element.setAttribute(attribute, newIdSelector)
        })
      })
    }

    if (!options.deep) return

    target.querySelectorAll('[id]').forEach(
      (element) => this.disambiguateId(element, { deep: false, block: target })
    )
  }
}

export default function (canvas) {
  return new BlockManager(canvas)
}
