import Control from './control.js'

export default class TextControl extends Control {
  setup () {
    if (this.block.tagName === 'INPUT') {
      this.control.value = this.block.value
    } else {
      this.control.value = this.block.innerText
    }
  }

  setValue (e) {
    const input = e.target
    if (!input.value) {
      return
    }

    if (this.block.tagName === 'INPUT') {
      this.block.value = input.value
      this.block.dataset.disableWith = input.value
    } else if (this.selection.hasOneElementChildOnly) {
      const firstChild = this.block.firstElementChild
      firstChild.innerText = input.value
    } else {
      this.block.innerText = input.value
    }
    this.changeCb()
  }
}