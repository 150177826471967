import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["addInput", "option", "list"]

  connect () {
    this.selectors = {
      itemTemplate: '#list-item-template',
      itemLabel: '.list-item-label',
      item: '.list-item'
    }

    this.settings = {
      ensureOptionExistence: false,
      ensureOptionUniqueness: true
    }

    this.addOnEnterLtn = (e) => this.addOnEnter(e)
    this.addInputTarget.addEventListener('keydown', this.addOnEnterLtn)

    this.itemTemplate = this.element.querySelector(this.selectors.itemTemplate)
    this.options = {}
    this.optionTargets.forEach((optionTarget) => this.options[slugify(optionTarget.value.toLowerCase())] = optionTarget)
    this.currentItems = new Set()
    this.element.querySelectorAll(this.selectors.item).forEach((item) => this.currentItems.add(this.getItemId(item)))

    this.listInitLtn = (e) => this.init(e.data)
    this.element.addEventListener('list:init', this.listInitLtn)
  }

  getItemId (item) {
    return item.querySelector('input').value
  }

  add () {
    const option = slugify(this.addInputTarget.value)
    if (!option) return
    let optionTarget = this.options[option]
    if (this.settings.ensureOptionExistence && !optionTarget) {
      showFlash(this.addInputTarget.dataset.notFound, true)
      return
    }

    if (!optionTarget) {
      optionTarget = { value: option, dataset : { id: option, text: option } }
    }

    if (this.settings.ensureOptionUniqueness && this.currentItems.has(optionTarget.dataset.id)) {
      showFlash(this.addInputTarget.dataset.notUnique, true)
      return
    }

    this.insert(optionTarget.value, optionTarget.dataset.text, optionTarget.dataset.id)
    this.notifyChange()
    return optionTarget
  }

  insert (key, text, id) {
    this.currentItems.add(key)
    const itemElement = document.importNode(this.itemTemplate.content, true)
    const itemLabel = itemElement.querySelector(this.selectors.itemLabel)
    itemLabel.querySelector('.text').textContent = text

    const hiddenField = document.createElement('input')
    hiddenField.name = this.addInputTarget.dataset.name
    hiddenField.setAttribute('type', 'hidden')
    hiddenField.setAttribute('value', id)

    itemLabel.appendChild(hiddenField)
    this.listTarget.appendChild(itemElement)
    this.addInputTarget.value = ''
  }

  addOnEnter (e) {
    if (e.which === 13 || e.keyCode === 13 || e.key === 'Enter' || e.code === 'Enter') {
      e.preventDefault()
      this.add()
      return false
    }
    return true
  }

  preventDefault (e) {
    e.preventDefault()
  }

  notifyChange () {
    const event = new Event('list:change')
    event.data = Array.from(this.currentItems).join(',')
    this.element.dispatchEvent(event)
  }

  beforeRemove(item) {
    this.notifyChange()
  }

  remove (e) {
    const item = e.currentTarget.parentElement
    this.currentItems.delete(this.getItemId(item))
    this.beforeRemove(item)
    item.remove()
  }

  init(data) {
    Array.from(this.listTarget.children).forEach((element) => element.remove())
    this.currentItems.clear()

    if (!data) return

    const options = data.split(',')

    options.forEach((option) => {
      this.insert(option, option, option)
    })
  }

  disconnect () {
    this.element.removeEventListener('list:init', this.listInitLtn)
    this.addInputTarget.removeEventListener('keydown', this.addOnEnterLtn)
  }
}
