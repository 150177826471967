import Control from './control.js'

export default class SectionFramesControl extends Control {
  static bridgeId = 'section_frames'

  setup () {
    if (this.attribute === 'list') {
      if (this.block.hasAttribute('data-shape-params')) {
        document.querySelector(".section-frames-controls").classList.remove('d-none')
        let shapeData = this.block.getAttribute('data-shape-params').split(',')
        let params = {
          shape: shapeData[0],
          width: shapeData[1],
          height: shapeData[2],
          fliped: shapeData[3] === '0' ? false : true,
          position: shapeData[4] === '0' ? false : true,
          color: shapeData[5],
        }
        this.bridge.setup(this.block, params)
      } else {
        document.querySelector(".section-frames-controls").classList.add('d-none')
      }
    }
  }

  setValue (e) {
    if (this.attribute === 'list') {
      if (this.control.value === 'none') {
        document.querySelector(".section-frames-controls").classList.add('d-none')
        this.block.removeAttribute('data-shape-params')
        let shape = this.block.querySelector('.custom-shape-divider')
        shape.parentNode.removeChild(shape)
      } else {
        document.querySelector(".section-frames-controls").classList.remove('d-none')
        this.bridge.setValue(this.block)
      }
    } else {
      this.bridge.setValue(this.block)
    }
  }
}