import Bridge from './bridge.js'

export default class BorderBridge extends Bridge {
  init () {
    this.imageConstrained = this.form.hasAttribute('data-imageconstrained')
  }

  setValue (block, dimension, value) {
    if (!value) {
      if (this.imageConstrained && dimension === 'width' && block.naturalWidth > 600) {
        this.setDimension(block, dimension, 600)
      } else {
        this.setDimension(block, dimension, null)
      }
      return
    }

    if (isNaN(value)) return
    if (value <= 0) return

    const originalWidth = this.getDimension(block, 'width')
    const originalHeight = this.getDimension(block, 'height')

    const keepRatio = !!originalWidth && !!originalHeight && this.controls.ratio.checked
    const ratio = () => originalWidth / originalHeight

    if (dimension === 'width') {
      this.setDimension(block, 'width', value)
      if (keepRatio) {
        this.setDimension(block, 'height', this.getDimension(block, 'width') / ratio())
        this.controls.height.value = this.getDimension(block, 'height')
      }
    } else {
      this.setDimension(block, 'height', value)
      if (keepRatio) {
        this.setDimension(block, 'width', this.getDimension(block, 'height') * ratio())
        this.controls.width.value = this.getDimension(block, 'width')
      }
    }
  }

  setDimension (block, dimension, value) {
    if (block.tagName == 'IMG') {
      if (value) {
        block.setAttribute(dimension, value)
      } else {
        block.removeAttribute(dimension)
      }
    } else {
      if (value) {
        block.style[dimension] = `${value}px`
      } else {
        block.style.removeProperty(dimension)
      }
    }
  }

  getDimension (block, dimension) {
    if (block.tagName === 'IMG') {
      return block.getAttribute(dimension)
    } else {
      return Helpers.extractPixelValue(block.style[dimension])
    }
  }
}