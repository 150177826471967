import Bridge from './bridge.js'

export default class BorderBridge extends Bridge {
  setup (block, side) {
    this.currentSide = side
    const style = block.style
    const borderProperty = `border${(side === 'All' ? '' : side)}`

    this.controls.borderRadius.value = window.Helpers.extractPixelValue(style.borderRadius)

    const width = block.dataset[`${borderProperty}Width`]
    this.controls.borderWidth.value = window.Helpers.extractPixelValue(width)

    const borderStyle = block.dataset[`${borderProperty}Style`]
    if (borderStyle) {
      window.Helpers.setSelectedValue(this.controls.borderStyle, borderStyle)
    }

    let color = block.dataset[`${borderProperty}Color`]
    color = color || block.dataset.borderColor || '#000'

    if (this.controls.borderColor.value !== color) {
      this.controls.borderColor.value = color
      this.controls.borderColor.dispatchEvent(new Event('pickr-change'))
    }
  }

  setValue (block) {
    const style = block.style
    const all = (this.currentSide === 'All')
    const borderProperty = `border${(all ? '' : this.currentSide)}`
    const attributes = new Array('Color', 'Style', 'Width')

    attributes.forEach((attribute) => {
      const property = `${borderProperty}${attribute}`
      const propertyForAll = `border${attribute}`

      block.dataset[property] = window.Helpers.getControlValue(this.controls[`border${attribute}`])
      if (attribute === 'Width' && block.dataset[property]){
        block.dataset[property] += 'px'
      }

      if (!all && block.dataset[property] === block.dataset[propertyForAll]) {
        block.dataset[property] = ''
      }
    })

    const borderWidth = this.controls.borderWidth.value
    if (all && (!borderWidth || isNaN(borderWidth))) {
      block.dataset[`${borderProperty}Width`] = '0px'
    }

    new Array('Left', 'Right', 'Top', 'Bottom').forEach((side) => {
      attributes.forEach((attribute) => {
        const property = `border${side}${attribute}`
        style[property] = block.dataset[property] || block.dataset[`border${attribute}`]
      })
    })

    if (all) {
      const borderRadius = this.controls.borderRadius.value
      if (!borderRadius || isNaN(borderRadius)) {
        style.removeProperty('borderRadius')
      } else {
        style.borderRadius = `${borderRadius}px`
      }
    }
  }
}