window.slugify = function slugify(str) {
  str = str.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();
  // remove accents, swap ñ for n, etc
  const from = "àáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
  const to   = "aaaaaeeeeiiiioooouuuunc------";
  for (let i=0, l=from.length ; i<l ; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str.replace(/[^a-z0-9 -]/g, '')
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-'); // collapse dashes
  return str;
}

document.addEventListener('turbolinks:load', ()=>{
  document.querySelectorAll('input[data-slug]').forEach((input) => {
    if (input.value) return
    const source = document.querySelector(input.dataset.slug)
    if (!source) return
    const slugifyLtn = () => {
      input.value = slugify(source.value)
    }
    slugifyLtn()
    source.addEventListener('input', slugifyLtn)
    input.addEventListener('input', ()=>{
      input.value = slugify(input.value)
      source.removeEventListener('input', slugifyLtn)
    })
  })

  document.querySelectorAll('input[data-slugify]').forEach((input) => {
    input.addEventListener('input', () => {
      input.value = slugify(input.value)
    })
  })
})
