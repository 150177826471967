import "jquery-circle-progress"

document.addEventListener("turbolinks:load", function() {
  const draw = (element) => {
    $(element).circleProgress({
      fill: {
        gradient: ["purple"]
      }
    })
  }

  document.querySelectorAll('.circle-progress').forEach((element) => {
    draw(element)
  })

  document.addEventListener('circle-progress:draw', function(e) {
    draw(e.target)
  })
})