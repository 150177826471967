import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["default"]

  connect () {
    this.defaultTarget.checked = "checked"
    this.showCollapsible(this.defaultTarget)
  }

  reveal(event) {
    this.showCollapsible(event.target)
  }

  showCollapsible(input) {
    const collapsible = this.element.querySelector(`#${input.value}`)
    $(collapsible).collapse('show')
  }
}
