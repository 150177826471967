import { BlockManager } from './block_manager.js'
import Rails from '@rails/ujs'
import { match } from './selection.js'
import { attachControls } from '../page_editor_controls'

const MATCHABLES_SELECTOR = '[data-match],[data-match-parent],[data-match-child],[data-match-onlychild],[data-match-ancestor],[data-match-section]'
const contentTypes = new Array('article', 'product', 'article_comments', 'botamp_pricing')

class InspectorManager {

  constructor (editor, canvas, inspector, form, changeCb) {
    this.afterChangeCb = changeCb
    this.editor = editor
    this.canvas = canvas
    this.form = form
    this.blockInspector = inspector.querySelector('.page-editor-inspector-block')
    this.pageInspector = inspector.querySelector('.page-editor-inspector-page')
    this.configsInput = this.form.querySelector('input.configs')

    this.beforeSubmitHooks = []

    this.matchables = this.blockInspector.querySelectorAll(MATCHABLES_SELECTOR)
    this.initControls()

    this.applyConfigs()
    this.addInspectorListeners()
    this.injectContentInBlocks()
  }

  initControls () {
    this.asyncLoads = 0
    this.controls = attachControls(this.canvas, this.editor, this.form, this.blockInspector)
    this.controls.forEach((control) => {
      control.actions = {
        incrementAsyncLoads: () => this.asyncLoads++,
        decrementAsyncLoads: () => this.decrementAsyncLoads(),
        lock: () => this.lock(),
        unlock: () => this.unlock(),
        reset: () => this.reset(),
        fillBlock: () => this.fillBlock(),
        registerBeforeSubmitHook: (params) => this.registerBeforeSubmitHook(params.func)
      }
    })
    if (this.asyncLoads === 0) this.canvas.dispatchEvent(new Event('canvas:loaded'))
  }

  decrementAsyncLoads () {
    if (0 > --this.asyncLoads) return
    this.canvas.dispatchEvent(new Event('canvas:loaded'))
  }

  selection () {
    return this.currentBlock
  }

  injectContentInBlocks () {
    const contentSelectors = contentTypes.map((type) => `[data-config-type="${type}"]`)
    document.querySelectorAll(contentSelectors.join(', ')).forEach((contentContainer) => {
      this.injectContent(contentContainer)
    })
  }

  showBlock (selection) {
    this.currentSelection = selection
    this.currentBlock = selection.block
    this.currentSection = selection.section
    this.fillBlock()

    this.pageInspector.style.display = 'none'
    this.blockInspector.style.display = 'block'
  }

  showPage () {
    this.currentBlock = null
    this.currentSection = null
    this.currentSelection = null

    this.pageInspector.style.display = 'block'
    this.blockInspector.style.display = 'none'
  }

  fillBlock () {
    this.matchables.forEach((matchable) => {
      if (match(matchable, this.currentSelection)) {
        matchable.style.display = 'block'
      } else {
        matchable.style.display = 'none'
      }
    })

    this.reset()
  }

  reset () {
    this.controls.forEach((control) => control.selection = this.currentSelection)
  }

  addInspectorListeners () {
    this.injectContentInBlocksLtn = (e) => this.injectContentInBlocks()
    this.canvas.addEventListener('blocks:configured', this.injectContentInBlocksLtn)

    this.injectContentLtn = (e) => this.injectContent(e.target)
    this.canvas.addEventListener('block:added', this.injectContentLtn)

    this.executeBeforeSubmitHooksLtn = (e) => this.executeBeforeSubmitHooks(e)
    this.form.addEventListener('submit', this.executeBeforeSubmitHooksLtn)
  }

  disposeControls () {
    this.controls.forEach((control) => control.dispose())
  }

  dispose () {
    this.form.removeEventListener('submit', this.executeBeforeSubmitHooksLtn)
    this.disposeControls()
    this.canvas.removeEventListener('blocks:configured', this.injectContentInBlocksLtn)
    this.canvas.removeEventListener('blocks:added', this.injectContentLtn)
  }

  injectContentInBlocks () {
    const contentSelectors = contentTypes.map((type) => `[data-config-type="${type}"]`)
    document.querySelectorAll(contentSelectors.join(',')).forEach((contentContainer) => {
      this.injectContent(contentContainer)
    })
  }

  injectContent (block) {
    if (!contentTypes.includes(block.dataset.configType)) return
    const contentName = `${block.dataset.configType}_content`
    const content = BlockManager.getBlock(contentName)
    if (!content) return
    block.appendChild(content)
  }

  registerBeforeSubmitHook (func) {
    this.beforeSubmitHooks.push(func)
  }

  executeBeforeSubmitHooks (e) {
    e.preventDefault()
    this.disposeControls()
    this.lock()

    this.hideCollapsibles()
    this.beforeSubmitHooks.forEach((func) => func.call())
    this.afterChangeCb()
    this.beforeSubmitHooks = []
    this.setConfigsHash()

    const afterAjaxCompleted = () => {
      this.initControls()
      this.applyConfigs()
      this.unlock()
      if (this.currentSelection) {
        this.reset()
      } else {
        this.showPage()
      }
      this.form.querySelectorAll('input[type="submit"][disabled]').forEach((input)=>{
        input.removeAttribute('disabled')
      })
      this.form.removeAttribute('data-draft')
      this.injectContentInBlocks()
    }

    const formData = new FormData(this.form)

    if (this.form.dataset.draft) formData.set('draft', 1)

    Rails.ajax({
      type: this.form.method.toUpperCase(),
      url: this.form.action,
      data: formData,
      success: afterAjaxCompleted,
      error: afterAjaxCompleted
    })
  }

  hideCollapsibles () {
    this.canvas.querySelectorAll('.collapse.show').forEach(
      (collapsible) => {
        collapsible.classList.remove('show')
        if (!collapsible.id) return
        const toggler = document.querySelector(`[data-target="#${collapsible.id}"]`)
        if (!toggler) return
        toggler.setAttribute('aria-expanded', false)
      }
    )
  }

  applyConfigs () {
    if (!this.configsInput || !this.configsInput.value) return
    if (this.configsInput.value === '{}') return

    const allConfigs = JSON.parse(this.configsInput.value)
    this.canvas.querySelectorAll('[data-config-id]').forEach((configurable)=>{
      const id = configurable.dataset.configId
      const configs = allConfigs[id]
      if (!configs) return
      for (const key in configs) {
        configurable.setAttribute(`data-config-${key}`, configs[key])
      }
    })

    this.afterChangeCb()
    this.canvas.dispatchEvent(new Event('blocks:configured'))
  }

  setConfigsHash () {
    if (!this.configsInput) return

    const configs = {}
    let id = -1

    this.canvas.querySelectorAll('[data-config-id]').forEach((configurable)=>{
      configurable.dataset.configId = ++id
      configs[id] = {}

      for (let i = 0; i < configurable.attributes.length; i++ ) {
        const attribute = configurable.attributes[i]
        const nodeName = attribute.nodeName
        if (nodeName.startsWith('data-config-') && nodeName !== 'data-config-id') {
          const key = nodeName.substring('data-config-'.length)
          configs[id][key] = attribute.nodeValue
        }
      }

      let empty = true
      for (const key in configs[id]) {
        configurable.removeAttribute(`data-config-${key}`)
        empty = false
      }

      if (empty) {
        delete configs[id]
        configurable.removeAttribute('data-config-id')
      } else {
        configurable.innerHTML = ''
        window.Helpers.changeTagName(configurable, 'DIV')
      }
    })

    this.configsInput.value = JSON.stringify(configs)
    this.afterChangeCb()
  }

  lock () {
    this.canvas.classList.add('locked')
    this.form.classList.add('locked')
  }

  unlock () {
    this.canvas.classList.remove('locked')
    this.form.classList.remove('locked')
  }
}

export default function (editor, canvas, inspector, form, changeCb) {
  return new InspectorManager(editor, canvas, inspector, form, changeCb)
}
