const createCheckoutSession = function(planId, productId) {
  const csrfToken = document.querySelector("[name='csrf-token']").content

  return fetch("/admin/billing", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken
    },
    body: JSON.stringify({
      plan_id: planId,
      product_id: productId
    })
  }).then(function(result) {
    return result.json()
  })
}

// Handle any errors returned from Checkout
const handleResult = function(result) {
  if (result.error) {
    window.showFlash(result.error.message, true)
  }
}

// Setup event handler to create a Checkout Session when button is clicked
document.addEventListener("click", function(e) {
  if (!event.target.matches("button[data-plan-id]")) {
    return
  }

  event.target.disabled = true

  const publishableKey = document.querySelector("meta[name='stripe-publishable-key']").content
  const stripe = Stripe(publishableKey)

  createCheckoutSession(
    e.target.dataset.planId,
    e.target.dataset.productId
  ).then(function(data) {
    // Call Stripe.js method to redirect to the new Checkout page
    stripe
      .redirectToCheckout({
        sessionId: data.sessionId
      })
      .then(handleResult)
  })
})
