class Selection {
  constructor(block, section) {
    this.block = block
    this.blockStyle = window.getComputedStyle(block)
    this.section = section
    this.hasTextOnly = this._hasTextOnly
    this.hasOneElementChildOnly = this._hasOneElementChildOnly
  }

  ancestorMatches (selector) {
    return !!this.block.closest(selector)
  }

  sectionMatches (selector) {
    return this.section.matches(selector)
  }

  matches (selector) {
    if (selector[0] !== '$') return this.block.matches(selector)
    if (selector === '$d-block') return this.blockStyle.display === 'block'
    if (selector === '$no-text') return !this.hasTextOnly
    return false
  }

  tagIs (tagName) {
    return this.block.tagName == tagName
  }

  childMatches (selector) {
    return !!this.block.querySelector(selector)
  }

  onlyChildMatches (selector) {
    return this.hasOneElementChildOnly && this.block.firstElementChild.matches(selector)
  }

  parentMatches (selector) {
    return this.block.parentElement.matches(selector)
  }

  get _hasTextOnly () {
    return this.block.children.length === 0
  }

  get _hasOneElementChildOnly() {
    const firstChild = this.block.firstElementChild

    if (!firstChild) {
      return false
    }

    if (firstChild.previousSibling && firstChild.previousSibling.textContent.trim() !== "") {
      return false
    }

    if (!firstChild.nextSibling) {
      return true
    }

    if (firstChild.nextSibling.nodeType !== Node.TEXT_NODE || firstChild.nextSibling.textContent.trim() !== "") {
      return false
    }

    return true
  }
}

export default function (block, section) {
  return new Selection(block, section)
}

export function match (element, selection) {
  const match = splitMatchAttribute(element, 'match')
  const matchParent = splitMatchAttribute(element, 'matchParent')
  const matchOnlychild = splitMatchAttribute(element, 'matchOnlychild')
  const matchChild = splitMatchAttribute(element, 'matchchild')
  const matchAncestor = splitMatchAttribute(element, 'matchAncestor')
  const matchSection = splitMatchAttribute(element, 'matchSection')

  const length = firstLength(
    match, matchParent, matchOnlychild, matchChild, matchAncestor, matchSection
  )

  for (let i = 0; i < length; i++) {
    if (
      (!match || !match[i] || selection.matches(match[i]))
      && (!matchSection || !matchSection[i] || selection.sectionMatches(matchSection[i]))
      && (!matchParent || !matchParent[i] || selection.parentMatches(matchParent[i]))
      && (!matchOnlychild || !matchOnlychild[i] || selection.onlyChildMatches(matchOnlychild[i]))
      && (!matchChild || !matchChild[i] || selection.childMatches(matchChild[i]))
      && (!matchAncestor || !matchAncestor[i] || selection.ancestorMatches(matchAncestor[i]))
    ) return true
  }
  return false
}

function splitMatchAttribute (element, attribute) {
  if (!element.dataset[attribute]) return
  return element.dataset[attribute].split('|')
}

function firstLength () {
  let length = 0
  for (let i = 0; i < arguments.length; ++i) {
    if (arguments[i]) {
      length = arguments[i].length
      break
    }
  } 
  return length
}