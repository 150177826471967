import Control from './control.js'
import Rails from '@rails/ujs'

const layoutClassOptions = new Array(
  'articles-one_column',
  'articles-two_columns',
  'articles-three_columns',
  'articles-list',
  'articles-full'
)

function toBoolean (value) {
  return value === 'true'
}

export default class BlogControl extends Control {
  static bridgeId = 'blog'

  get event () {
    return this.control.dataset.controlEvent || 'change'
  }

  setup () {
    const articlesContainer = this.articlesContainer
    const value = articlesContainer.getAttribute(this.attribute)

    if (Array.from(['checkbox', 'radio']).includes(this.control.type)) {
      this.value = toBoolean(value || this.defaultValue)
    } else {
      this.value = value
    }
  }

  setValue (e) {
    const articlesContainer = this.articlesContainer
    articlesContainer.setAttribute(this.attribute, this.value)
    this.update(articlesContainer)
    this.changeCb()
  }

  update (articlesContainer) {
    if (this.attribute === 'data-config-full') {
      layoutClassOptions.forEach((klass) => this.block.classList.remove(klass))
      const klass = this.control.checked ? 'articles-full' : 'articles-two_columns'
      this.bridge.updateClass(this.block, articlesContainer, klass)
      this.bridge.injectArticles(this.block)
    } else if (this.attribute === 'data-config-class') {
      layoutClassOptions.forEach((klass) => this.block.classList.remove(klass))
      this.block.classList.add(articlesContainer.dataset.configClass)
    } else if (this.attribute === 'data-config-limit') {
      this.bridge.injectArticles(this.block)
    } else if (this.attribute === 'data-config-all' || this.attribute === 'data-config-infinite-scroll') {
      this.bridge.updatePagination(articlesContainer)
      this.bridge.updateLoadingStatus(articlesContainer)
    } else if (this.attribute.startsWith('data-config-show')) {
      this.bridge.applyShow(this.attribute.split('-')[3], articlesContainer)
    } else if (this.attribute === 'data-config-link') {
      this.bridge.applyArticlesLink(this.articlesContainer)
    }
  }

  get articlesContainer () {
    return this.block.querySelector('.articles')
  }

  get defaultValue () {
    return this.control.dataset.controlDefault
  }
}