import Control from './control.js'
import Rails from '@rails/ujs'

export default class SearchControl extends Control {
  _super(that, method, args) {
    SearchControl.prototype[method].apply(that, args || [])
  }

  init () {
    this.resultsContainer = document.querySelector(this.control.dataset.controlResultsContainer)
    this.results = this.resultsContainer.querySelector('.search-results')
    this.searchCollapse = this.resultsContainer.closest('.collapse')
  }

  setup () {
    this.results.querySelectorAll('.result').forEach((link) => link.remove())
    this.resultsContainer.classList.add('empty')
    $(this.searchCollapse).collapse('hide')
  }

  setValue (e) {
    const searchTerm =  this.control.value

    if (this.searchTimeout) {
      this.searchTimeoutTmp = this.searchTimeout
      clearTimeout(this.searchTimeout)
      if (!searchTerm) {
        this.results.querySelectorAll('.result').forEach((link) => link.remove())
      }
    }

    if (searchTerm) {
      this.resultsContainer.classList.add('loading')
      this.searchTimeout = setTimeout(()=>this.search(searchTerm), 1200)
    } else {
      this.resultsContainer.classList.remove('loading')
      this.emptyResults()
    }
  }

  get searchPath () {
    return '/admin/pages/search'
  }

  searchResultClicked (result) {
    return () => {
      if (this.block.tagName === 'A') {
        this.bridge.setHref(result.path)
      } else if (this.block.tagName === 'IMG') {
        let redirectInput = document.querySelector('#block-link')
        redirectInput.value = result.path
      } else return
      $(this.searchCollapse).collapse('hide')
      this.changeCb()
    }
  }

  searchResultsReceived (results) {

  }

  emptyResults () {
    this.results.querySelectorAll('.result').forEach((result) => result.remove())
  }

  searchResultInnerHTML(result) {
    return `<span class="mr-2">${result.name}</span><span class="badge badge-light">${result.type}</span>`
  }

  search (searchTerm) {
    if (this.searchTimeoutTmp && this.searchTimeout === this.searchTimeoutTmp) {
      return
    }

    this.emptyResults()

    Rails.ajax({
      type: 'GET',
      url: this.searchPath,
      data: `q=${searchTerm}`,
      success: (response) => {
        const results = Array.from(response.results)
        this.searchResultsReceived(results)
        results.forEach((result)=>{
          const link = document.createElement('a')
          link.setAttribute('class', 'list-group-item result')
          link.innerHTML = this.searchResultInnerHTML(result)
          this.results.appendChild(link)
          link.addEventListener('click', this.searchResultClicked(result))
        })

        if (response.results.length == 0) {
          this.resultsContainer.classList.add('empty')
        }

        this.resultsContainer.classList.remove('loading')
      },
      error: () => {
        this.resultsContainer.classList.remove('loading')
      }
    })
  }
}
