import Control from './control.js'

export default class DimensionControl extends Control {
  static bridgeId = 'dimension'

  setup () {
    if (this.block.tagName === 'A' && this.selection.onlyChildMatches('img')) {
      this.image = this.block.firstElementChild
    }
  }

  setValue (e) {
    if (this.attribute === 'ratio') return
    this.bridge.setValue(this.image || this.block, this.attribute, this.control.value)
    this.changeCb()
  }
}