import Rails from '@rails/ujs'

document.addEventListener("turbolinks:load", function() {

  $("#sendCampaignTest").click(function(e){

    e.preventDefault();

    var data = {
        'emails': $("#emails").val(),
        'subject': $("#campaign_subject").val(),
        'text': $("#campaign_text").val()
    }

    Rails.ajax({
      type: "POST",
      url: "/admin/campaigns/send_campaign_test",
      data: new URLSearchParams(data).toString(),
      success: (response) => {
        showFlash(response.notice, false)
      },
      error: (response) => {
        showFlash(response.notice, true)
      },
    })

  });

});
