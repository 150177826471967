import SearchControl from './search_control.js'
import Rails from '@rails/ujs'

const loadingClasses = new Array('spinner-border', 'spinner-border-sm', 'mx-auto', 'text-secondary')

export default class SearchProductsControl extends SearchControl {
  get searchPath () {
    return '/admin/products/search'
  }

  init () {
    this._super(this, 'init')
    this.orderProductName = document.querySelector('#block-order-product-name')
  }

  searchResultsReceived (results) {
    if (!this.bridge.products) this.bridge.products = {}

    results.forEach((result) => {
      this.bridge.products[result.hashid] = result
    })
  }

  setup () {
    this._super(this, 'setup')
    this.fetchProduct()
  }

  fetchProduct () {
    const hashid = this.block.dataset.orderProduct
    if (!hashid) return
    const product = this.bridge.products[hashid]

    if (product) {
      this.setProductName(product.name)
      return
    }

    loadingClasses.forEach((klass) => this.orderProductName.classList.add(klass))
    this.orderProductName.role = "status"
    this.orderProductName.innerHTML = `<span class="sr-only">${I18n.loading}</span>`

    const afterAjaxCompleted = () => {
      loadingClasses.forEach((klass) => this.orderProductName.classList.remove(klass))
      this.orderProductName.removeAttribute('role')
    }

    Rails.ajax({
      type: 'GET',
      url: `/admin/products/${hashid}`,
      success: (response) => {
        this.bridge.products[hashid] = response.product
        this.setProductName(response.product.name)
        afterAjaxCompleted()
      },
      error: (response) => {
        this.block.setAttribute('data-order-product', '')
        afterAjaxCompleted()
        this.orderProductName.innerHTML = ''
      }
    })
  }

  setProductName (name) {
    this.orderProductName.innerHTML = name
  }

  searchResultClicked (result) {
    return () => {
      if (!this.block.hasAttribute('data-order-product')) return
      this.block.dataset.orderProduct = result.hashid
      $(this.searchCollapse).collapse('hide')
      this.orderProductName.innerHTML = result.name
      this.changeCb()
    }
  }

  searchResultInnerHTML(result) {
    return `<span class="mr-2">${result.name}</span>`
  }
}
