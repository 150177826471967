import SimpleListController from "./simple_list_controller"


export default class extends SimpleListController {

  connect () {
    super.connect()
    this.selectors = {
      ...this.selectors,
      itemIndex: '.list-item-index',
      actionUp: '.list-action-up',
      actionDown: '.list-action-down'
    }
    this.settings = {
      ...this.settings,
      ensureOptionExistence: true
    }
    this.setActionButtonsState()
  }

  moveDown (e) {
    this.select(e)
    const nextSibling = this.selected.nextElementSibling
    this.swapIndex(this.selected, nextSibling)
    nextSibling.parentElement.insertBefore(nextSibling, this.selected)
    this.setActionButtonsState()
  }

  moveUp (e) {
    this.select(e)
    const previousSibling = this.selected.previousElementSibling
    this.swapIndex(this.selected, previousSibling)
    this.selected.parentElement.insertBefore(this.selected, previousSibling)
    this.setActionButtonsState()
  }

  swapIndex (sortableA, sortableB) {
    const sortableAIndexElement = sortableA.querySelector(this.selectors.itemIndex)
    const sortableBIndexElement = sortableB.querySelector(this.selectors.itemIndex)
    const indexA = sortableAIndexElement.textContent
    sortableAIndexElement.textContent = sortableBIndexElement.textContent
    sortableBIndexElement.textContent = indexA
  }

  add (e) {
    const optionTarget = super.add(e)
    if(!optionTarget) return

    const itemElement = this.listTarget.lastElementChild
    const itemLabel = itemElement.querySelector(this.selectors.itemLabel)
    itemLabel.querySelector('.type').textContent = optionTarget.dataset.type
    const index = this.currentItems.size
    itemElement.querySelector(this.selectors.itemIndex).textContent = index
    this.setActionButtonsState()
  }

  preventDefault (e) {
    e.preventDefault()
  }

  beforeRemove(item) {
    let currentElement = item.nextElementSibling
    while (currentElement) {
      const indexElement = currentElement.querySelector(this.selectors.itemIndex)
      indexElement.textContent = parseInt(indexElement.textContent) - 1
      currentElement = currentElement.nextElementSibling
    }
  }

  remove (e) {
    super.remove(e)
    this.setActionButtonsState()
  }

  select (e) {
    this.selected = this.selected || e.currentTarget.parentElement
    this.selected.classList.remove('border-primary')
    this.selected = e.currentTarget.parentElement
    this.selected.classList.add('border-primary')
    this.setActionButtonsState()
  }

  setActionButtonsState() {
    if (!this.listTarget.firstElementChild) return
      
    for (const sortable of this.listTarget.children) {
      sortable.querySelector(this.selectors.actionUp).classList.remove('locked')
      sortable.querySelector(this.selectors.actionDown).classList.remove('locked')
    }
   
    this.listTarget.firstElementChild.querySelector(this.selectors.actionUp).classList.add('locked')
    this.listTarget.lastElementChild.querySelector(this.selectors.actionDown).classList.add('locked')
  }
}
