import Control from './control.js'

export default class HrefControl extends Control {
  init () {
    this.bridge.setHref = (href) => this.setHref(href)
  }

  setHref (href) {
    this.block.href = href
    this.control.value = href
    this.control.classList.add('highlight')
    setTimeout(() => this.control.classList.remove('highlight'), 650)
  }
}