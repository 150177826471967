import Control from './control.js'
import Rails from '@rails/ujs'

export default class FrameControl extends Control {
    static bridgeId = 'iframe'

    setup() {
        this.iframe = this.block
        this.srcInput = this.matchable.querySelector('#block-iframe')
        this.srcInput.value = this.iframe.getAttribute('src')
    }

    setValue(e) {
        const src = this.srcInput.value
        if (window.Helpers.isValidHttpUrl(src)) {
            this.callAction('lock')
            Rails.ajax({
                type: "GET",
                url: `/admin/page_editor/iframe?src=${src}`,
                success: (response) => {
                    const html = response.html
                    if (!html) {
                        showFlash(I18n.editor.iframe_error, true)
                        return
                    }
                    this.iframe = window.Helpers.replaceElement(this.iframe, html)
                    this.callAction('unlock')
                },
                error: (response) => {
                    showFlash(I18n.editor.iframe_error, true)
                }
            })
            this.changeCb()
        } else {
            showFlash(I18n.editor.src_error, true)
        }
    }
}