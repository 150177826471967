import Control from './control.js'

export default class RichtextControl extends Control {
  setup () {
    $(this.control).summernote('code', this.block.innerHTML)
  }

  setValue (e) {
    const textarea = e.target
    if (!textarea.value) {
      return
    }
    this.block.innerHTML = textarea.value
    this.changeCb()
  }
}