// Diff implementation that only tries to idenfy the stem that changed
// inspired by https://github.com/jhchen/fast-diff/blob/master/diff.js
// Intentionally non minimal output

const DIFF_DELETE = -1
const DIFF_INSERT = 1
const DIFF_EQUAL = 0

function diff (text1, text2) {
  if (text1 === text2) {
    if (text1) {
      return [text1.length]
    }
    return []
  }

  const commonPrefixLength = diff_commonPrefix(text1, text2)
  text1 = text1.substring(commonPrefixLength)
  text2 = text2.substring(commonPrefixLength)

  const commonSuffixLength = diff_commonSuffix(text1, text2)
  text1 = text1.substring(0, text1.length - commonSuffixLength)
  text2 = text2.substring(0, text2.length - commonSuffixLength)

  return [
    commonPrefixLength,
    [DIFF_DELETE, text1],
    [DIFF_INSERT, text2],
    commonSuffixLength
  ]
}

/**
 * Determine the common prefix of two strings.
 * @param {string} text1 First string.
 * @param {string} text2 Second string.
 * @return {number} The number of characters common to the start of each
 *     string.
 */
function diff_commonPrefix(text1, text2) {
  // Quick check for common null cases.
  if (!text1 || !text2 || text1.charAt(0) !== text2.charAt(0)) {
    return 0
  }
  // Binary search.
  // Performance analysis: http://neil.fraser.name/news/2007/10/09/
  var pointermin = 0
  var pointermax = Math.min(text1.length, text2.length)
  var pointermid = pointermax
  var pointerstart = 0
  while (pointermin < pointermid) {
    if (
      text1.substring(pointerstart, pointermid) ==
      text2.substring(pointerstart, pointermid)
    ) {
      pointermin = pointermid
      pointerstart = pointermin
    } else {
      pointermax = pointermid
    }
    pointermid = Math.floor((pointermax - pointermin) / 2 + pointermin)
  }

  if (is_surrogate_pair_start(text1.charCodeAt(pointermid - 1))) {
    pointermid--
  }

  return pointermid
};


/**
 * Determine the common suffix of two strings.
 * @param {string} text1 First string.
 * @param {string} text2 Second string.
 * @return {number} The number of characters common to the end of each string.
 */
function diff_commonSuffix(text1, text2) {
  // Quick check for common null cases.
  if (!text1 || !text2 || text1.slice(-1) !== text2.slice(-1)) {
    return 0
  }
  // Binary search.
  // Performance analysis: http://neil.fraser.name/news/2007/10/09/
  var pointermin = 0
  var pointermax = Math.min(text1.length, text2.length)
  var pointermid = pointermax
  var pointerend = 0
  while (pointermin < pointermid) {
    if (
      text1.substring(text1.length - pointermid, text1.length - pointerend) ==
      text2.substring(text2.length - pointermid, text2.length - pointerend)
    ) {
      pointermin = pointermid
      pointerend = pointermin
    } else {
      pointermax = pointermid
    }
    pointermid = Math.floor((pointermax - pointermin) / 2 + pointermin)
  }

  if (is_surrogate_pair_end(text1.charCodeAt(text1.length - pointermid))) {
    pointermid--
  }

  return pointermid
}

function is_surrogate_pair_start(charCode) {
  return charCode >= 0xD800 && charCode <= 0xDBFF
}

function is_surrogate_pair_end(charCode) {
  return charCode >= 0xDC00 && charCode <= 0xDFFF
}


diff.INSERT = DIFF_INSERT
diff.DELETE = DIFF_DELETE
diff.EQUAL = DIFF_EQUAL

module.exports = diff