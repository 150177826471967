import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "editor", "toggle" ]

  connect () {
    this.toggleLtn = (e) => this.toggle()
    this.toggleTarget.addEventListener('click', this.toggleLtn, true)
  }

  disconnect () {
    this.toggleTarget.removeEventListener('click', this.toggleLtn, true)
  }

  toggle () {
    this.editorTarget.classList.toggle('page-editor-tools-hidden')

    // Force reflow so that toggle button is shown at proper place between toggles
    this.editorTarget.style.display = 'none'
    this.editorTarget.offsetHeight
    this.editorTarget.style.display = 'block'
  }
}
