import CodeMirror from "codemirror"
import "codemirror/mode/css/css"
import "codemirror/mode/htmlmixed/htmlmixed"

document.addEventListener("turbolinks:load", function() {
  let codeTextareas = document.querySelectorAll('[data-code]')

  if (!codeTextareas) {
    return
  }

  codeTextareas.forEach(function (codeTextarea) {
    const codeMirror = CodeMirror.fromTextArea(codeTextarea, {
      mode: codeTextarea.dataset.code
    })

    codeTextarea.addEventListener('code:refresh', function () {
      codeMirror.refresh()
    })

    codeMirror.on('changes', function () {
      codeTextarea.value = codeMirror.getValue()
      codeTextarea.dispatchEvent(new Event('change'))
    })
  })
})