import { Controller } from "stimulus"
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = ["content", "youtubeModal", "youtubeIframe"]

  connect () {
    this.closeContentLtn = (event) => this.closeContent(event)
    if (this.hasYoutubeIframeTarget) {
      this.youtubeSrc = this.youtubeIframeTarget.dataset.src
      this.youtubeIframeTarget.src = ''
    }

    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('show_onboarding')) {
      this.toggleContent()
    }
    if (!this.contentTarget.classList.contains('d-none')) {
      window.addEventListener('click', this.closeContentLtn)
    }
  }

  toggleYoutubeModal(event) {
    event.preventDefault()
    if (this.youtubeModalTarget.style.display == 'block') {
      this.youtubeModalTarget.style.display = 'none'
      this.youtubeIframeTarget.src = ""
    } else {
      this.youtubeIframeTarget.src = this.youtubeSrc
      setTimeout(()=> {
        this.youtubeModalTarget.style.display = 'block'
      }, 400)
    }
  }

  closeContent (event) {
    if (event.target.closest('#onboarding-widget')) return
    this.contentTarget.classList.add('d-none')
    window.removeEventListener('click', this.closeContentLtn)
  }

  toggleContent() {
    if (this.contentTarget.classList.toggle('d-none')) {
      window.removeEventListener('click', this.closeContentLtn)
    } else {
      window.addEventListener('click', this.closeContentLtn)
    }
  }

  next(event) {
    event.preventDefault()
    const button = event.target
    button.disabled = true
    Rails.ajax({
      type: "PUT",
      url: event.target.dataset.url,
      success: (response) => {
        if (response.is_last) {
          this.element.innerHTML = ''
          return
        }
        const div = document.createElement('div')
        div.innerHTML = response.html
        this.element.innerHTML = div.firstElementChild.innerHTML
        this.element.querySelector('.circle-progress').dispatchEvent(new Event('circle-progress:draw', { bubbles: true }))
      },
      error: (e) => {
        button.parentElement.classList.add('d-none')
      }
    })
  }

  hide (event) {
    event.preventDefault()

    if (confirm(I18n.onboarding.confirm_hide_widget)) {
      const button = event.target
      button.disabled = true
      Rails.ajax({
        type: "PUT",
        url: event.target.dataset.url,
        success: (_) => {
          this.element.innerHTML = ''
        },
        error: (_) => {
          showFlash(I18n.generic_error, true)
          button.removeAttribute('disabled')
        }
      })
    }
  }

  disconnect () {
    window.removeEventListener('click', this.closeContentLtn)
  }
}
