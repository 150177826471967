import Control from './control.js'

export default class TextFormatControl extends Control {
  setup () {
    this.value = (
      this.selection.blockStyle[this.attribute] === this.control.value
    )
  }

  get event () {
    return 'click'
  }

  setValue (e) {
    this.value = this.control.checked

    if (this.control.type === 'checkbox') {
      this.block.style[this.attribute] = (this.control.checked ? this.control.value : 'normal')
      this.changeCb()
      return
    }

    if (!this.control.checked) return

    this.block.style[this.attribute] = this.control.value
    this.value = true
    this.changeCb()
  }
}