// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "polyfill"

import ColorPicker from "colorpicker"
ColorPicker.buildColorPickersOnLoad()

import "helpers"
import "bootstrap"
import "bootstrap_turbo"
import "alert"
import "error"
import "file"
import "flash"
import "tabs"
import "carousel"
import "dropdown"
import "filter"
import "font_preview"
import "controllers"
import "tel-input"

import "admin/circle_progress"
import "admin_controllers"
import "admin/accounts"
import "admin/analytics"
import "admin/charting"
import "admin/billing"
import "admin/code"
import "admin/copy"
import "admin/disable_navigation"
import "admin/binding"
import "admin/daterange"
import "admin/slug"
import "admin/translations"
import "admin/summernote"
import "admin/articles"
import "admin/onboarding"
import "admin/campaign"
import "admin/hide"

Turbolinks.setProgressBarDelay(0)
