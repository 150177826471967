import Control from './control.js'

export default class AnimationPreviewControl extends Control {

    setup() {}

    setValue(e) {
        e.preventDefault()
        this.block.addEventListener('animationend', () => {
            this.block.classList.remove('animate__animated', `animate__${this.block.dataset.animation}`)
            this.block.style.animationDuration = ''
            this.block.style.animationDelay = ''
            this.block.style.animationIterationCount = ''
        })
        if (this.block.hasAttribute('data-wow-duration'))
            this.block.style.animationDuration = this.block.dataset.wowDuration
        if (this.block.hasAttribute('data-wow-delay'))
            this.block.style.animationDelay = this.block.dataset.wowDelay
        if (this.block.hasAttribute('data-wow-iteration'))
            this.block.style.animationIterationCount = this.block.dataset.wowIteration
        
        this.block.classList.add('animate__animated', `animate__${this.block.dataset.animation}`)
    }
}
