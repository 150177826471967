import Control from './control.js'
import Rails from '@rails/ujs'

const layoutClassOptions = new Array(
  'products-one_column',
  'products-two_columns',
  'products-three_columns',
  'products-list'
)

function toBoolean (value) {
  return value === 'true'
}

export default class CatalogControl extends Control {
  static bridgeId = 'catalog'

  get event () {
    return this.control.dataset.controlEvent || 'change'
  }

  setup () {
    const productsContainer = this.productsContainer
    const value = productsContainer.getAttribute(this.attribute)

    if (Array.from(['checkbox', 'radio']).includes(this.control.type)) {
      this.value = toBoolean(value || this.defaultValue)
    } else {
      this.value = value
    }
  }

  setValue (e) {
    const productsContainer = this.productsContainer
    productsContainer.setAttribute(this.attribute, this.value)
    this.update(productsContainer)
    this.changeCb()
  }

  update (productsContainer) {
    if (this.attribute === 'data-config-class') {
      layoutClassOptions.forEach((klass) => this.block.classList.remove(klass))
      this.block.classList.add(productsContainer.dataset.configClass)
    } else if (this.attribute === 'data-config-limit') {
      this.bridge.injectProducts(this.block)
    } else if (this.attribute === 'data-config-all' || this.attribute === 'data-config-infinite-scroll') {
      this.bridge.updatePagination(productsContainer)
      this.bridge.updateLoadingStatus(productsContainer)
    } else if (this.attribute.startsWith('data-config-show')) {
      this.bridge.applyShow(this.attribute.split('-')[3], productsContainer)
    } else if (this.attribute === 'data-config-link') {
      this.bridge.applyProductsLink(this.productsContainer)
    }
  }

  get productsContainer () {
    return this.block.querySelector('.products')
  }

  get defaultValue () {
    return this.control.dataset.controlDefault
  }
}